import React from "react";
import { Badge, Card, CardBody, Col, Row, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CargaTarjeta = (props) => {
    const info = props.data;
    const clientes_mostrados = [];
    return (
        <Card key={Math.random()} style={{marginBottom:'3px'}}>
            <CardBody>
                <Row>
                    <Col xs="12" sm="12" md="3" lg="3">
                        <Row key={Math.random()}>
                            <Col xs="12"><h3 className="text-center">Carga #{info.no_carga}</h3></Col>
                            <Col xs="12" className="text-center" style={{marginBottom:'5px'}}>
                                {info.detalle.length === 0 && info.estatus === 1 ?
                                <Badge color="secondary" style={{color:'white'}} pill>Carga sin iniciar</Badge>
                                : info.estatus === 2 ? 
                                <Badge color="warning" style={{color:'black'}} pill>Carga por verificar</Badge>
                                : info.estatus === 3 ? 
                                <Badge color="success" style={{color:'white'}} pill>Carga finalizada</Badge>
                                :
                                <Badge color="primary" style={{color:'white'}} pill>Carga en proceso</Badge>
                                }
                            </Col>
                        </Row>
                        {info.detalle.length === 0 && info.estatus === 1 ?
                            <Row key={Math.random()}>
                                <Col xs="12" sm="12" md="6" lg="6">
                                    <Button className="btn-block btn-sm" color="warning" onClick={() => props.functionEdit(info.id_carga)}><FontAwesomeIcon icon="fas fa-edit" /></Button>
                                </Col>
                                <Col xs="12" sm="12" md="6" lg="6">
                                    <Button className="btn-block btn-sm" color="danger" onClick={() => props.functionDelete(info.id_carga)}><FontAwesomeIcon icon="fas fa-trash-alt" /></Button>
                                </Col>
                            </Row>
                            : ""
                        }
                    </Col>
                    <Col xs="12" sm="12" md="9" lg="9">
                        <Row>
                            <Col><label style={{fontWeight:'bold'}}>Propuesta de carga:</label></Col>
                        </Row>
                        { info.tipo_viaje == 'N' && 
                        info.propuesta.map(row => {
                            let cosecha = props.cultivos.find(item => item.id_cosecha == row.id_cosecha);
                            let cliente = cosecha.clientes.find(item => parseInt(item.c_codigo_dis) == row.cliente);
                            if(!clientes_mostrados.includes(row.cliente)){
                                clientes_mostrados.push(row.cliente);
                                return <Row key={Math.random()} style={{padding:'2px'}}>
                                    <Col xs="12">
                                        <label style={{fontWeight:'bold'}}>Cliente: {cliente['c_codigo_dis']} - {cliente['v_nombre_dis']}</label>
                                    </Col>{
                                        info.propuesta.map(roww => {
                                            if(row.cliente == roww.cliente){
                                            return <Col xs="12" key={Math.random()}>
                                                <Badge style={{color:'white', fontSize:'16px'}} color="primary">{roww.cantidad}</Badge>{' - '} 
                                                <span>{roww.cultivo} {roww.atributo} {roww.size} {roww.presentacion} {roww.envase} {roww.bultos} BULTOS {roww.tipo_siembra}</span>
                                            </Col>
                                            }
                                        })
                                    }
                            </Row>
                            }
                        }
                        )}
                        { info.tipo_viaje == 'E' && info.propuesta.map(row => {
                            let cosecha = props.cultivos.find(item => item.id_cosecha == row.id_cosecha);
                            let cliente = cosecha.clientes.find(item => parseInt(item.c_codigo_dis) == row.cliente);
                            return <Row key={Math.random()} style={{padding:'2px'}}>
                                <Col xs="12">
                                    <Badge style={{color:'white', fontSize:'16px'}} color="primary">{row.cantidad}</Badge>{' - '} 
                                    <span>{row.cultivo} {row.atributo} {row.size} {row.presentacion} {row.envase} {row.bultos} BULTOS {row.tipo_siembra}</span>
                                </Col>
                            </Row>
                        }
                        )}
                        { info.detalle.length > 0 &&
                            <>
                            <hr style={{marginTop:'5px', marginBottom:'1px'}}/>
                            <Row>
                                <Col><label style={{fontWeight:'bold'}}>Detalle del carga</label></Col>
                            </Row>
                            {info.detalle.map(row => (
                                <Row key={Math.random()} style={{padding:'2px'}}>
                                    <Col>
                                        <Badge style={{color:'white', fontSize:'16px'}} color="primary">{row.cantidad}</Badge>{' - '} 
                                        <span>{row.cultivo} {row.atributo} {row.size} {row.presentacion} {row.envase} {row.bultos} BULTOS {row.tipo_siembra}</span>
                                    </Col>
                                </Row>)
                            )}
                            </>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}