import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Table } from "reactstrap";
import Swal from "sweetalert2";
import { api } from "../../config/api";
import { PRESENTACION } from "../utils/constants";

export const InventarioTable = ({ tipo, inventario, reload }) => {
  const deletePalet = (palet) => {
    Swal.fire({
      title: "¿Está seguro que desea eliminar el palet " + palet + "?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .put(
            "/api/inventario/deletePalet_inventory",
            { data: palet },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + sessionStorage.token,
              },
            }
          )
          .then((response) => {
            reload();
            // cargarInventario();
            Swal.close();
            Swal.fire({
              icon: "success",
              title: response.data.msg,
              timer: 1500,
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "Oh oh, algo salio mal!",
            });
          });
      }
    });
  };

  return (
    <Row>
      <Col xs="12" style={{ height: "70vh", overflowY: "scroll" }}>
        <Table
          responsive
          size="sm"
          className="table-bordered table-condensed table-hover"
        >
          <thead>
            {tipo === PRESENTACION ? (
              <tr>
                <th>Cosecha</th>
                <th>Producto</th>
                <th>Tamaño</th>
                <th>Presentación</th>
                <th>Envase</th>
                <th>Bultos</th>
                <th>Tipo siembra</th>
                <th>Cantidad</th>
              </tr>
            ) : (
              <tr>
                <th>Pallet</th>
                <th>Cosecha</th>
                <th>Producto</th>
                <th>Tamaño</th>
                <th>Presentación</th>
                <th>Envase</th>
                <th>Bultos</th>
                <th>Tipo siembra</th>
                <th>Cantidad</th>
                <th>Acción</th>
              </tr>
            )}
          </thead>
          <tbody>
            {tipo === PRESENTACION
              ? inventario?.map((row, index) => (
                  <tr key={index}>
                    <td>{row.id_cosecha__cosecha}</td>
                    <td>
                      {row.cultivo} {row.atributo}
                    </td>
                    <td>{row.size}</td>
                    <td>{row.presentacion}</td>
                    <td>{row.envase}</td>
                    <td>{row.bultos}</td>
                    <td>{row.tipo_siembra}</td>
                    <td>{row.cantidad}</td>
                  </tr>
                ))
              : inventario?.map((row) => (
                  <tr key={row.codigo_palet}>
                    <td>{row.codigo_palet}</td>
                    <td>{row.id_cosecha__cosecha}</td>
                    <td>
                      {row.cultivo} {row.atributo}
                    </td>
                    <td>{row.size}</td>
                    <td>{row.presentacion}</td>
                    <td>{row.envase}</td>
                    <td>{row.bultos}</td>
                    <td>{row.tipo_siembra}</td>
                    <td>1</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => deletePalet(row.codigo_palet)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};
