import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useAppContext } from "../config/UserProvider";
import { api } from "../config/api";
import { ElementosMenu } from "./generales/elementosMenu";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fab, fas, far);

export const SideNavigation = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [menu, setMenu] = useState([]);
  const useContext = useAppContext();

  // added styles
  const styles = {
    sideBarHeight: {
      height: "100vh",
    },
    sideBarProperties: {
      height: "100vh",
      position: "sticky",
      top: "0px",
    },
    menuIcon: {
      float: "right",
      margin: "10px",
    },
  };
  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };
  const logout = () => {
    useContext.handleLogout();
  };
  useEffect(() => {
    api
      .post(
        "/api/administracion/obtener/permisos/",
        {
          data: {
            idusuario: JSON.parse(sessionStorage.user)["id"],
            tipo_modulo: "web",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      )
      .then((res) => {
        setMenu(res.data);
      });
  }, []);
  return (
    <ProSidebar style={styles.sideBarProperties} collapsed={collapsed}>
      <SidebarHeader>
        <div style={styles.menuIcon} onClick={onClickMenuIcon}>
          <AiOutlineMenu />
        </div>
      </SidebarHeader>
      <Menu iconShape="square">
        {menu.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon="fa-solid fa-spinner" spin size="xl" />
          </div>
        ) : (
          menu.map((row) =>
            row.id_padre === null && row.ruta === null ? (
              <SubMenu
                key={row.id_modulo}
                title={row.modulo}
                icon={<FontAwesomeIcon icon={row.icono} />}
              >
                <ElementosMenu
                  id_padre={row.id_modulo}
                  menu={menu}
                ></ElementosMenu>
              </SubMenu>
            ) : row.id_padre === null && row.ruta !== null ? (
              <MenuItem
                key={row.id_modulo}
                icon={<FontAwesomeIcon icon={row.icono} />}
              >
                <Link to={row.ruta}>{row.modulo}</Link>
              </MenuItem>
            ) : (
              ""
            )
          )
        )}
        <MenuItem
          key={"cerrarSesion"}
          icon={<FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />}
          onClick={() => {
            logout();
          }}
        >
          <Link to="/">Cerrar Sesión</Link>
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};
