import { useState } from "react";
import { useUserForm } from "../hooks/useUserForm";
import { UserForm } from "./User.Form";
import { Button } from "@tremor/react";
import { useAddUser } from "../hooks/useAddUser";

export function UserDetails({ user, onUpdate, onCancel }) {
  const { updateUser } = useAddUser();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    await updateUser(values);
    setLoading(false);
    onUpdate();
  };

  const {
    values,
    handleInputChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useUserForm({
    initialValues: {
      id_usuario: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      cultivo: user.cultivo,
      username: user.username,
      editar: true,
      password: "",
      email: user.email,
    },
    onSubmit,
  });

  return (
    <>
      <UserForm
        handleSubmit={handleSubmit}
        handleInputChange={handleInputChange}
        errors={errors}
        touched={touched}
        values={values}
        loading={loading}
        setFieldValue={setFieldValue}
      />
      <Button
        color="red"
        type="button"
        onClick={onCancel}
        variant="secondary"
        className="border-red-500"
      >
        Cancelar
      </Button>
    </>
  );
}
