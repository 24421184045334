import { Button, TextInput } from "@tremor/react";
// import { useCultivo } from "../hooks/useCultivo";
import { Text } from "./Text";

export function UserForm({
  handleSubmit,
  touched,
  errors,
  handleInputChange,
  values,
  loading,
}) {
  const { first_name, last_name, username, password, email } = values;
  // const cultivo_list = useCultivo();

  // const handleCultivoChange = (value) => setFieldValue("cultivo", value);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-5">
      <div>
        <Text>Nombre</Text>
        <TextInput
          value={first_name}
          onChange={handleInputChange}
          name="first_name"
          placeholder=""
          error={(errors.first_name && touched.first_name) !== undefined}
        />
      </div>
      <div>
        <Text>Apellidos</Text>
        <TextInput
          value={last_name}
          onChange={handleInputChange}
          name="last_name"
          placeholder=""
          error={(errors.last_name && touched.last_name) !== undefined}
        />
      </div>
      {/* <div>
        <Text>Cultivo</Text>
        <Select
          value={cultivo}
          onChange={handleCultivoChange}
          placeholder="NINGUNO"
          name="cultivo"
        >
          <SelectItem value={0}>NINGUNO</SelectItem>
          {cultivo_list.cultivos.map((cultivo) => (
            <SelectItem key={cultivo.id_cosecha} value={cultivo.id_cosecha}>
              {cultivo.cosecha}
            </SelectItem>
          ))}
        </Select>
      </div> */}
      <div>
        <Text>Usuario</Text>
        <TextInput
          value={username}
          onChange={handleInputChange}
          name="username"
          placeholder=""
          error={(errors.username && touched.username) !== undefined}
        />
      </div>
      <div>
        <Text>Contraseña</Text>
        <TextInput
          type="password"
          value={password}
          onChange={handleInputChange}
          name="password"
          placeholder=""
          error={(errors.password && touched.password) !== undefined}
        />
      </div>
      <div>
        <Text>Correo</Text>
        <TextInput
          value={email}
          onChange={handleInputChange}
          name="email"
          placeholder=""
        />
      </div>
      <Button loading={loading} type="submit">
        Guardar
      </Button>
    </form>
  );
}
