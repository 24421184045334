import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { PreenfrioCard } from "../components/Preenfrio.Card";
import Swal from "sweetalert2";
import { api } from "../../config/api";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";

const getTargetDate = (fecha_inicio_ciclo, hora_inicio, duracion_ciclo) => {
  let targetDate = moment();
  if (fecha_inicio_ciclo) {
    const hora = hora_inicio.split(":")[0];
    const minuto = hora_inicio.split(":")[1];
    const seconds = hora_inicio.split(":")[2];
    targetDate = moment(fecha_inicio_ciclo)
      .set("hour", hora)
      .set("minute", minuto)
      .set("seconds", seconds)
      .add(duracion_ciclo, "s");
  }
  return targetDate;
};

const getColorStatus = (fecha, duracion_ciclo, estatus, inicio) => {
  const disponible = "#D6F8DF";
  const pendiente = "#F7F8D9";
  const ocupado = "#FFEBEB";
  const iconDisponible = "#41A74B";
  const iconPendiente = "#D3B138";
  const iconOcupado = "#FC9F9F";
  let color = disponible;
  let iconColor = iconDisponible;
  if (fecha) {
    if (estatus === 2) {
      color = ocupado;
      iconColor = iconOcupado;
    }
    if (
      moment(fecha + " " + inicio).add(duracion_ciclo, "s") <= moment() &&
      estatus !== 1
    ) {
      color = pendiente;
      iconColor = iconPendiente;
    }
  }

  return { backgroundColor: color, icon: iconColor };
};

export const PreenfrioBitacora = () => {
  const [form, setForm] = useState({
    inicio: moment().format("YYYY-MM-DD"),
    fin: moment().format("YYYY-MM-DD"),
    id_camara: 0,
    palet: "",
  });
  const [preenfrioList, setPreenfrioList] = useState([]);
  const [preenfrios, setPreenfrios] = useState([]);
  const [detallePreenfrio, setDetallePreenfrio] = useState([]);
  const [bitacoraPreenfrio, setBitacoraPreenfrio] = useState([]);
  const [modal, setModal] = useState(false);
  const [idPreenfrio, setIdPreenfrio] = useState(0);
  const [isVisible, setIsVisible] = useState({
    camaras: false,
    bitacora: false,
  });
  const [camara, setCamara] = useState({});

  const [bitacora, setBitacora] = useState(false);

  const toggle = () => setModal(!modal);
  window.camarasListado = preenfrioList;

  const mostrarDetallePreenfrio = (id_preenfrio) => {
    api
      .post(
        "/api/preenfrio/detalle",
        { id_preenfrio },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      )
      .then((res) => {
        setDetallePreenfrio(res.data.detalle);
        setBitacoraPreenfrio(res.data.bitacora);
      });
    toggle();
  };
  const cargarLista = () => {
    if (
      form.fin === "" ||
      form.inicio === "" ||
      parseInt(form.id_camara) === 0
    ) {
      Swal.fire({
        icon: "info",
        title: "Favor de llenar todos los campos",
      });
      return;
    }
    api
      .post(
        "/api/preenfrio/bitacora",
        { id_camara: form.id_camara, inicio: form.inicio, final: form.fin },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      )
      .then((res) => {
        Swal.close();
        setPreenfrios(res.data);
      });
  };
  const buscar = () => {
    let existe = true;
    for (let i in preenfrioList) {
      if (preenfrioList[i].detalle !== undefined) {
        const palet = preenfrioList[i].detalle.filter(
          (item) => item.codigo_palet.trim() === form.palet.trim()
        );

        if (palet.length > 0) {
          setCamara(preenfrioList[i]);
          setIsVisible({
            ...isVisible,
            camaras: true,
          });
          existe = false;
          break;
        }
      }
    }

    if (existe) {
      Swal.fire({
        icon: "info",
        title: "Palet no encontrado",
        timer: 1500,
      });
    }
  };

  const downloadDetallePreenfrioExcel = (id_preenfrio) => {
    Swal.showLoading();
    api
      .post(
        "api/preenfrio/download-detalle-excel/",
        {
          id_preenfrio: id_preenfrio,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? "Ship-In - Detalle preenfrio.xlsx"
            : suggestedFileName;
        FileSaver.saveAs(response.data, effectiveFileName);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        Swal.close();
      });
  };

  useEffect(() => {
    Swal.showLoading();
    api
      .get("api/preenfrio/obtener/camaras", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      })
      .then((response) => {
        setPreenfrioList(response.data);
      })
      .finally(() => {
        Swal.close();
      });
  }, []);

  return (
    <>
      {bitacora && (
        <Row>
          <Col>
            <Row>
              <Col xs="12" sm="12" md="2" lg="2">
                <Button
                  className="btn-block"
                  color="danger"
                  onClick={() => setBitacora(!bitacora)}
                >
                  Regresar
                </Button>
              </Col>
              <Col xs="12" sm="12" md="8" lg="8">
                <h2 className="text-center">
                  BITACORA DE CAMARAS DE PREENFRIO
                </h2>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="12" md="2" lg="2">
                <label style={{ fontWeight: "bold" }}>Fecha inicio</label>
                <Input
                  type="date"
                  value={form.inicio}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      inicio: e.target.value,
                    })
                  }
                ></Input>
              </Col>
              <Col xs="12" sm="12" md="2" lg="2">
                <label style={{ fontWeight: "bold" }}>Fecha final</label>
                <Input
                  type="date"
                  value={form.fin}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      fin: e.target.value,
                    })
                  }
                ></Input>
              </Col>
              <Col xs="12" sm="12" md="2" lg="2">
                <label style={{ fontWeight: "bold" }}>Preenfrio</label>
                <Input
                  className="form-control"
                  type="select"
                  value={form.id_camara}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      id_camara: e.target.value,
                    });
                  }}
                >
                  <option value={0}>Seleccionar...</option>
                  {preenfrioList.map((row) => (
                    <option key={row.id_camara} value={row.id_camara}>
                      {row.camara}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col xs="12" sm="12" md="2" lg="2">
                <Button
                  className="btn-block"
                  style={{ marginTop: "31px" }}
                  color="primary"
                  onClick={() => cargarLista()}
                >
                  Cargar
                </Button>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Table
                  responsive
                  size="sm"
                  className="table-bordered table-condensed table-hover"
                >
                  <thead>
                    <tr>
                      <th>Folio</th>
                      <th>Cosecha</th>
                      <th>Estatus</th>
                      <th>Fecha</th>
                      <th>Hora</th>
                      <th>Usuario</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {preenfrios.map((row) => (
                      <tr key={row.id_preenfrio}>
                        <td>#{row.id_preenfrio}</td>
                        <td>{row.cosecha}</td>
                        <td>
                          {row.etapa === "Final" ? "Finalizado" : "En proceso"}
                        </td>
                        <td>{row.fecha}</td>
                        <td>{row.hora}</td>
                        <td>
                          {row.first_name} {row.last_name}
                        </td>
                        <td>
                          <Button
                            className="btn-sm"
                            onClick={() => {
                              mostrarDetallePreenfrio(row.id_preenfrio);
                              setIdPreenfrio(row.id_preenfrio);
                            }}
                            color="primary"
                          >
                            Detalle
                          </Button>{" "}
                          <button
                            className="btn btn-outline-success btn-sm"
                            onClick={() =>
                              downloadDetallePreenfrioExcel(row.id_preenfrio)
                            }
                          >
                            <FontAwesomeIcon icon="far fa-file-excel" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {!bitacora && (
        <Row>
          <Col>
            <Row>
              <Col xs="12" sm="12" md="2" lg="2"></Col>
              <Col xs="12" sm="12" md="8" lg="8">
                <h2 className="text-center">ESTADO DE PREENFRIOS</h2>
              </Col>
              <Col xs="12" sm="12" md="2" lg="2">
                <Button
                  className="btn-block"
                  color="primary"
                  onClick={() => setBitacora(!bitacora)}
                >
                  Bitacora detallada
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={3}>
                <label style={{ fontWeight: "bold" }}>Palet</label>
                <input
                  className="form-control"
                  value={form.palet}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      palet: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col sm={12} md={3}>
                <button
                  className="btn btn-primary"
                  style={{ marginTop: "30px" }}
                  onClick={() => buscar()}
                >
                  Buscar
                </button>
              </Col>
            </Row>
            <Row>
              {preenfrioList.map((row) => {
                const color = getColorStatus(
                  row.fecha,
                  row.duracion_ciclo,
                  row.estatus,
                  row.hora_inicio
                );
                return (
                  <PreenfrioCard
                    key={row.id_camara}
                    fecha={getTargetDate(
                      row.fecha,
                      row.hora_inicio,
                      row.duracion_ciclo
                    )}
                    camara={row.camara}
                    iconColor={color.icon}
                    backgroundColor={color.backgroundColor}
                    showTimer={row.estatus === 2}
                    ciclo={row.ciclo}
                    estatus={row.estatus}
                    onClick={() => {
                      setCamara(row);
                      setIsVisible({
                        ...isVisible,
                        camaras: true,
                      });
                    }}
                    disabled={row.estatus === 1}
                  />
                );
              })}
            </Row>
          </Col>
        </Row>
      )}
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader>Detalle del preenfrio</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <button
                className="btn btn-outline-success"
                onClick={() => downloadDetallePreenfrioExcel(idPreenfrio)}
              >
                <FontAwesomeIcon icon="far fa-file-excel" /> Descargar
              </button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <Table
                responsive
                size="sm"
                className="table-bordered table-condensed table-hover"
              >
                <thead>
                  <tr>
                    <td>Etapa</td>
                    <td>Temperatura</td>
                    <td>Hora</td>
                    <td>Fecha</td>
                  </tr>
                </thead>
                <tbody>
                  {bitacoraPreenfrio.map((row) => (
                    <tr key={row.id_registro}>
                      <td>{row.etapa.toUpperCase()}</td>
                      <td>{row.temperatura} °F</td>
                      <td>{row.hora.substring(0, 8)}</td>
                      <td>{row.fecha}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col>
              <Table
                responsive
                size="sm"
                className="table-bordered table-condensed table-hover"
              >
                <thead>
                  <tr>
                    <td>Código Palet</td>
                    <td>Producto</td>
                    <td>Presentacion</td>
                    <td>Contenido</td>
                    <td>Tipo Siembra</td>
                    <td>Fecha empaque</td>
                  </tr>
                </thead>
                <tbody>
                  {detallePreenfrio.map((row) => (
                    <tr key={row.id_detalle}>
                      <td>{row.codigo_palet}</td>
                      <td>
                        {row.cultivo} {row.atributo} {row.size}
                      </td>
                      <td>{row.presentacion}</td>
                      <td>
                        {row.envase} {row.bultos} BULTOS
                      </td>
                      <td>{row.tipo_siembra}</td>
                      <td>{row.fecha}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isVisible.camaras}
        toggle={() => {
          setIsVisible({
            ...isVisible,
            camaras: false,
          });
          setCamara({});
        }}
        size="xl"
      >
        <ModalHeader>
          DETALLE {camara.camara?.toUpperCase()} - CICLO #{camara.ciclo}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="table-responsive">
              <Table
                responsive
                size="sm"
                className="table-bordered table-condensed table-hover"
              >
                <thead>
                  <tr>
                    <th>Código Palet</th>
                    <th>Producto</th>
                    <th>Presentacion</th>
                    <th>Contenido</th>
                    <th>Tipo Siembra</th>
                  </tr>
                </thead>
                <tbody>
                  {camara.detalle?.map((item) => (
                    <tr key={item.codigo_palet}>
                      <td>{item.codigo_palet}</td>
                      <td>{item.cultivo}</td>
                      <td>{item.presentacion}</td>
                      <td>
                        {item.envase} {item.bultos} BULTOS
                      </td>
                      <td>{item.tipo_siembra}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};
