import { Row, Col } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import { useEmpleados } from "../hooks/useEmpleados";
import { useCultivo } from "../hooks/useCultivo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEmpaque } from "../hooks/useEmpaque";

export const FormEtiquetaEmpaque = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  setValues,
  loading,
}) => {
  const nomina = useRef();
  const { getEmpleado } = useEmpleados();
  const { cultivos } = useCultivo();
  const [idCultivo, setIdCultivo] = useState(0);
  const { empaques } = useEmpaque(idCultivo);

  const searchUser = async (nomina_value, empacador, event) => {
    event.preventDefault();
    const empleado = await getEmpleado(nomina_value, empacador);
    if (empleado.length > 0) {
      setValues({
        ...values,
        nomina: empleado[0].num_nomina,
        nombre: empleado[0].nombre,
        paterno: empleado[0].paterno,
        materno: empleado[0].materno,
        empacador: empleado[0].num_empacador ? empleado[0].num_empacador : "",
      });
      return;
    }

    if (nomina_value !== "") {
      setValues({
        ...values,
        nombre: "",
        paterno: "",
        materno: "",
        empacador: "",
      });
    }

    if (empacador !== "") {
      setValues({
        ...values,
        nomina: "",
        nombre: "",
        paterno: "",
        materno: "",
      });
    }
  };

  useEffect(() => {
    nomina.current.focus();
  }, []);

  useEffect(() => {
    setIdCultivo(values.cosecha);
  }, [values.cosecha]);

  return (
    <form onSubmit={handleSubmit} className="flex-1">
      <Row>
        <Col>
          <label>Número de nómina</label>
          <input
            className="form-control"
            ref={nomina}
            value={values.nomina}
            onChange={handleChange("nomina")}
            style={
              errors.nomina && touched.nomina ? { borderColor: "red" } : {}
            }
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                searchUser(values.nomina, "", event);
              }
            }}
          />
        </Col>
        <Col>
          <label>Número de empacador</label>
          <input
            type="number"
            className="form-control"
            value={values.empacador}
            onChange={handleChange("empacador")}
            style={
              errors.empacador && touched.empacador
                ? { borderColor: "red" }
                : {}
            }
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                searchUser("", values.empacador, event);
              }
            }}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <label>Nombre</label>
          <input
            className="form-control"
            value={values.nombre}
            name="nombre"
            onChange={handleChange}
            style={
              errors.nombre && touched.nombre ? { borderColor: "red" } : {}
            }
          />
        </Col>
        <Col>
          <label>Apellido paterno</label>
          <input
            className="form-control"
            value={values.paterno}
            name="paterno"
            onChange={handleChange}
            style={
              errors.paterno && touched.paterno ? { borderColor: "red" } : {}
            }
          />
        </Col>
        <Col>
          <label>Apellido materno</label>
          <input
            className="form-control"
            value={values.materno}
            name="materno"
            onChange={handleChange}
            style={
              errors.materno && touched.materno ? { borderColor: "red" } : {}
            }
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <label>Cultivo</label>
          <select
            className="form-control"
            value={values.cosecha}
            onChange={handleChange("cosecha")}
            style={
              errors.cosecha && touched.cosecha ? { borderColor: "red" } : {}
            }
          >
            <option value={0}>Seleccionar...</option>
            {cultivos?.map((item) => (
              <option key={item.id_cosecha} value={item.id_cosecha}>
                {item.cosecha}
              </option>
            ))}
          </select>
        </Col>
        <Col>
          <label>Tipo de empaque</label>
          <select
            className="form-control"
            value={values.empaque}
            onChange={handleChange("empaque")}
            style={
              errors.empaque && touched.empaque ? { borderColor: "red" } : {}
            }
          >
            <option value={0}>Seleccionar...</option>
            {values.cosecha !== 0 &&
              empaques?.map((item) => (
                <option key={item.id_empaque} value={item.id_empaque}>
                  {item.empaque}
                </option>
              ))}
          </select>
        </Col>
        <Col>
          <label>Cantidad de etiquetas</label>
          <input
            className="form-control"
            value={values.cantidad}
            onChange={handleChange("cantidad")}
            style={
              errors.cantidad && touched.cantidad ? { borderColor: "red" } : {}
            }
            type="number"
            min="0"
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={8}></Col>
        <Col>
          <button
            className="btn btn-success btn-block"
            style={{ marginTop: "30px" }}
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
            ) : (
              "Imprimir"
            )}
          </button>
        </Col>
      </Row>
    </form>
  );
};
