import { useEffect, useState } from "react";
import { date } from "yup";
import { api } from "../../config/api";
import { displayRequestError } from "../utils/request.error";

const schema = date().min("2022-01-01").max("3000-01-01");

export const useEmpaque = (id_cosecha) => {
  const [empaques, setEmpaques] = useState([]);
  const [total, setTotal] = useState();

  const generateTotal = (xempaques) => {
    const xtotal = {};
    xempaques.forEach((empaque) => {
      xtotal[empaque.empaque] = 0;
    });
    setTotal({ ...xtotal });
  };

  useEffect(() => {
    const url = id_cosecha === 0 ? "" : `?cultivo=${id_cosecha}`;
    api
      .get(`api/empaque/empaque/${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        setEmpaques(response.data);
        generateTotal(response.data);
      })
      .catch((error) => {
        displayRequestError(error);
      });
  }, [id_cosecha]);

  return { empaques, total };
};

export const useReporteVersus = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async (id_cultivo, fecha_inicio, fecha_fin) => {
    const isValid = await schema.isValid(fecha_inicio);
    if (!isValid) {
      return;
    }
    setLoading(true);
    try {
      const url = `api/empaque/versus/?id_cultivo=${id_cultivo}&fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`;
      const response = await api.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (error) {
      displayRequestError(error);
      setLoading(false);
    }
  };

  return { data, loading, fetchData };
};

export const useReporteResumen = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async (id_cultivo, fecha_inicio, fecha_fin) => {
    const isValid = await schema.isValid(fecha_inicio);
    if (!isValid) {
      return;
    }
    setLoading(true);
    try {
      const url = `api/empaque/resumen/?id_cultivo=${id_cultivo}&fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`;
      const response = await api.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (error) {
      displayRequestError(error);
      setLoading(false);
    }
  };

  return { data, loading, fetchData };
};
