import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, SubMenu } from "react-pro-sidebar";

export const ElementosMenu = (props) => {
  return props.menu.map((row) =>
    row.id_padre === props.id_padre ? (
      row.ruta !== null ? (
        <MenuItem
          key={row.id_modulo}
          icon={<FontAwesomeIcon icon={row.icono} />}
        >
          <Link to={row.ruta}>{row.modulo}</Link>
        </MenuItem>
      ) : (
        <SubMenu
          key={row.id_modulo}
          title={row.modulo}
          icon={<FontAwesomeIcon icon={row.icono} />}
        >
          <ElementosMenu
            id_padre={row.id_modulo}
            menu={props.menu}
          ></ElementosMenu>
        </SubMenu>
      )
    ) : (
      ""
    )
  );
};
