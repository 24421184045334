import { Row, Col, Input, Button } from "reactstrap";
import { useCultivos } from "../hooks/useCultivos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PRESENTACION, PALET } from "../utils/constants";

export const InventarioFilterForm = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  downloadExcel,
}) => {
  const { cultivos } = useCultivos();

  return (
    <form onSubmit={handleSubmit}>
      <Row style={{ marginBottom: "5px" }}>
        <Col xs="12" sm="12" md="4" lg="3">
          <label style={{ fontWeight: "bold" }}>Cosechas:</label>
          <Input
            type="select"
            value={values.id_cosecha}
            onChange={handleChange}
            name="id_cosecha"
            className="form-control"
          >
            <option value="0">Traer todas...</option>
            {cultivos.map((row) => (
              <option key={row.id_cosecha} value={row.id_cosecha}>
                {row.cosecha}
              </option>
            ))}
          </Input>
        </Col>
        <Col xs="12" sm="12" md="4" lg="3">
          <label style={{ fontWeight: "bold" }}>Visualización:</label>
          <Input
            type="select"
            className="form-control"
            value={values.tipo}
            onChange={handleChange}
            name="tipo"
          >
            <option value={PRESENTACION}>Por presentación</option>
            <option value={PALET}>Por número de palet</option>
          </Input>
        </Col>
        <Col xs="12" sm="12" md="2" lg="2">
          <Button
            type="sumbit"
            className="btn-block"
            style={{ marginTop: "31px" }}
            color="primary"
          >
            Cargar
          </Button>
        </Col>
        <Col xs="12" sm="12" md="2" lg="2">
          <Button
            type="button"
            className="btn-block"
            onClick={downloadExcel}
            style={{ marginTop: "31px" }}
            color="success"
          >
            <FontAwesomeIcon icon="fas fa-file-excel" />
          </Button>
        </Col>
      </Row>
    </form>
  );
};
