/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Button } from "@tremor/react";
import { TabsControl } from "../../components/Tabs.Control";
import { Resumen } from "../../components/Empaque.Resumen";
import { Row, Col } from "reactstrap";
import { useFormik } from "formik";
import { useCultivo } from "../../hooks/useCultivo";
import { object, number, date } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import { api } from "../../../config/api";
import { useReporteResumen } from "../../hooks/useEmpaque";
import moment from "moment";
import { displayRequestError } from "../../utils/request.error";
import { EmpacadoresList } from "../../components/Empacadores.List";

const initialValues = {
  cultivo: 0,
  fecha_inicio: moment().format("YYYY-MM-DD"),
  fecha_fin: moment().format("YYYY-MM-DD"),
};

const Reporte = () => {
  const { cultivos } = useCultivo();
  const TABS = [
    {
      label: "RESUMEN",
      value: "resumen",
      ref: useRef(),
    },
    {
      label: "EMPACADORES",
      value: "empacadores",
      ref: useRef(),
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const resumen = useReporteResumen();

  const downloadExcel = async () => {
    try {
      const selected_cultivo = cultivos.find(
        (item) => item.id_cosecha === parseInt(form.values.cultivo)
      );
      const response = await api.post(
        "api/empaque/reporte/",
        {
          id_cultivo: form.values.cultivo,
          fecha_inicio: form.values.fecha_inicio,
          fecha_fin: form.values.fecha_fin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
          responseType: "blob",
        }
      );

      const effectiveFileName = `Destajo empaque ${
        selected_cultivo.cosecha
      } ${moment(form.values.fecha).format("DD/MM/YYYY")}.xlsx`;
      FileSaver.saveAs(response.data, effectiveFileName);
    } catch (error) {
      displayRequestError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (values) => {
    setLoading(true);
    resumen.fetchData(values.cultivo, values.fecha_inicio, values.fecha_fin);
    setLoading(false);
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (cultivos.length === 1) {
      form.setValues({
        ...form.values,
        cultivo: cultivos[0].id_cosecha,
      });
    }
  }, [cultivos]);

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <h3>Reportes</h3>
          </Col>
        </Row>
        <br />
        <form onSubmit={form.handleSubmit}>
          <Row>
            <Col>
              <label>Cultivo</label>
              <select
                className="form-control"
                value={form.values.cultivo}
                onChange={form.handleChange("cultivo")}
                style={
                  form.errors.cultivo && form.touched.cultivo
                    ? { borderColor: "red" }
                    : {}
                }
              >
                <option value={0}>Seleccionar...</option>
                {cultivos?.map((item) => (
                  <option key={item.id_cosecha} value={item.id_cosecha}>
                    {item.cosecha}
                  </option>
                ))}
              </select>
            </Col>
            <Col>
              <label>Fecha inicio</label>
              <input
                className="form-control"
                type="date"
                value={form.values.fecha_inicio}
                onChange={form.handleChange}
                name="fecha_inicio"
                style={
                  form.errors.fecha && form.touched.fecha
                    ? { borderColor: "red" }
                    : {}
                }
              />
            </Col>
            <Col>
              <label>Fecha fin</label>
              <input
                className="form-control"
                type="date"
                value={form.values.fecha_fin}
                onChange={form.handleChange}
                name="fecha_fin"
                style={
                  form.errors.fecha && form.touched.fecha
                    ? { borderColor: "red" }
                    : {}
                }
              />
            </Col>
            <Col style={{ marginTop: "32px" }} className="flex flex-row gap-5">
              <Button loading={loading} type="submit">
                Buscar
              </Button>
              <button
                className="btn btn-outline-success"
                onClick={downloadExcel}
                type="button"
                disabled={loading}
              >
                {loading ? (
                  <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                ) : (
                  <FontAwesomeIcon icon="far fa-file-excel" />
                )}
              </button>
            </Col>
          </Row>
        </form>
        <br />
        <Row>
          <Col>
            <TabsControl
              name="group"
              callback={(index) => {
                setSelectedIndex(index);
              }}
              controlRef={useRef()}
              segments={TABS}
              defaultIndex={selectedIndex}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="table-responsive">
            {selectedIndex === 0 && (
              <Resumen data={resumen.data} loading={resumen.loading} />
            )}
            {selectedIndex === 1 && <EmpacadoresList />}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Reporte;

const validationSchema = object({
  cultivo: number().moreThan(0).required("Favor de seleccionar un cultivo"),
  fecha_inicio: date().required("Favor de seleccionar una fecha"),
  fecha_fin: date().required("Favor de seleccionar una fecha"),
});
