import { BarChart as Chart, Card } from "@tremor/react";

export const BarChart = ({ categories, chartdata, title, colors }) => {
  return (
    <Card>
      <h3 className="text-tremor-title text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">
        {title}
      </h3>
      <br />
      <div className="overflow-x-auto w-full p-2">
        <Chart
          data={chartdata}
          index="name"
          categories={categories}
          colors={colors}
          yAxisWidth={48}
        />
      </div>
    </Card>
  );
};
