import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Resumen = ({ data, loading }) => {
  if (loading) {
    return (
      <div style={style.container}>
        <FontAwesomeIcon icon="fas fa-spinner" size="lg" spin />
      </div>
    );
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th>NÓMINA</th>
          <th>NOMBRE EMPACADOR</th>

          {data?.empaque?.map((empaque, index) => (
            <th key={empaque.id_empaque}>{empaque.empaque}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.empleado?.map((empleado) => (
          <tr key={empleado.num_nomina}>
            <td>{empleado.num_nomina}</td>
            <td>{empleado.nombre}</td>

            {data?.empaque?.map((empaque, index) => (
              <td key={empaque.id_empaque}>
                {empleado.empaque.find(
                  (item) => item.empaque === empaque.empaque
                ).cantidad ?? "$0"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "25px",
    margin: "20px",
    padding: "20px",
  },
};
