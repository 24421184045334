import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { string, number, object } from "yup";
import { api } from "../../../config/api";
import { displayRequestError } from "../../utils/request.error";
import { FormEtiquetaEmpaque } from "../../components/Form.Etiqueta.Empaque";
import { ImprimirQRCode } from "../../components/Imprimir.QRCode";

const initialValues = {
  nomina: "",
  nombre: "",
  materno: "",
  paterno: "",
  empacador: "",
  empaque: "",
  cantidad: "",
  cosecha: 0,
};

const AltaEmpacador = () => {
  const [loading, setLoading] = useState(false);
  const [folioList, setFolioList] = useState([]);
  const [empaque, setEmpaque] = useState("");
  const [empacador, setEmpacador] = useState("");

  const onSubmit = (values) => {
    setLoading(true);
    api
      .post(
        "api/empaque/empaque/",
        {
          nomina: values.nomina,
          id_empaque: values.empaque,
          cantidad: values.cantidad,
          empacador: values.empacador,
          nombre: values.nombre,
          paterno: values.paterno,
          materno: values.materno,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      )
      .then((response) => {
        setFolioList(response.data.folios);
        setEmpaque(response.data.empaque);
        setEmpacador(response.data.empacador);
      })
      .catch((error) => {
        displayRequestError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { values, errors, touched, handleSubmit, handleChange, setValues } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  useEffect(() => {
    if (folioList.length > 0) {
      const content = document.getElementById("imprimir").innerHTML;
      const popupWindow = window.open("", "_blank", "width=600,height=600");
      popupWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      popupWindow.document.write(content);
      popupWindow.document.write("</body></html>");
      popupWindow.document.close();
      popupWindow.print();
      setTimeout(() => {
        popupWindow.close();
      }, 1500);
    }
  }, [folioList]);

  return (
    <div className="flex flex-row gap-2">
      <FormEtiquetaEmpaque
        values={values}
        errors={errors}
        touched={touched}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        setValues={setValues}
        loading={loading}
      />
      <div
        id="imprimir"
        className="max-h-96 border bg-slate-200 rounded-sm grid grid-rows-4 grid-flow-col flex-1 overflow-auto"
      >
        <div className="qr-container">
          {folioList?.map((item, index) => (
            <ImprimirQRCode
              key={item}
              code={item}
              empaque={empaque}
              empacador={empacador}
              margin={(index + 1) % 35 === 1}
            />
          ))}
        </div>
        <style>
          {`
          .qr-container {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 25px; 
          }
          label {
            font-weight: bold;
            text-align: center;
          }
          .qr-style {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;
          }
          body {
            margin: 0px;
          }
          @media print{
            .mt-1{
              page-break-before: always;
            }
          }
        `}
        </style>
      </div>
    </div>
  );
};

export default AltaEmpacador;

const validationSchema = object({
  nomina: string().required("Ingresa el número de nómina"),
  nombre: string().required(),
  materno: string().required(),
  paterno: string().required(),
  empacador: number().moreThan(0).required("Ingresar el numero de empacador"),
  empaque: number().moreThan(0).required("Seleccione un tipo de empaque"),
  cantidad: number().moreThan(0).required(),
  cosecha: number().moreThan(0).required("Seleccione un cultivo"),
});
