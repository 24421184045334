import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/styles.css";
export const LargeListButton = ({ title, style, onClick }) => {
  return (
    <button className="button-container" onClick={onClick} style={style}>
      <div className="button-body">{title}</div>
      <div style={{ marginRight: "10px" }}>
        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
      </div>
    </button>
  );
};
