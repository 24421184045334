import { UserForm } from "./User.Form";
import { useUserForm } from "../hooks/useUserForm";
import { useAddUser } from "../hooks/useAddUser";
import { UserSchema as validationSchema } from "../utils/validations";
import { useState } from "react";

export function AddUser({ onAdded }) {
  const [loading, setLoading] = useState(false);
  const { addUser } = useAddUser();

  const onSubmit = async (values) => {
    setLoading(true);
    await addUser(values);
    setLoading(false);
    resetForm();
    onAdded();
  };

  const {
    values,
    handleInputChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useUserForm({
    initialValues: {
      first_name: "",
      last_name: "",
      username: "",
      editar: false,
      password: "",
      email: "",
    },
    onSubmit,
    validationSchema,
  });

  return (
    <UserForm
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      errors={errors}
      touched={touched}
      values={values}
      loading={loading}
      setFieldValue={setFieldValue}
    />
  );
}
