import { Button, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../../config/api";
import Swal from "sweetalert2";

export const UserTable = ({ users, handleModify, refresh }) => {
  const desactivar = (id_usuario) => {
    Swal.fire({
      title: "¿Desea dar de baja al usuario?",
      text: "El usuario no podra acceder al sistema o aplicación movil",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de baja!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post(
            "/api/administracion/usuarios/cambiarEstatus",
            { id_usuario, estatus: false },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + sessionStorage.token,
              },
            }
          )
          .then((res) => {
            if (res.data) {
              Swal.fire(
                "El usuario se dió de baja",
                "Si lo desea se puede reactivar al usuario en un futuro",
                "success"
              );
              refresh();
            } else {
              Swal.fire(
                "Algo ocurrió",
                "Comuniquese con el administrador del sistema",
                "error"
              );
            }
          });
      }
    });
  };
  const reactivar = (id_usuario) => {
    Swal.fire({
      title: "¿Desea reactivar al usuario?",
      text: "El usuario podra acceder al sistema y aplicación web",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, reactivar!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post(
            "/api/administracion/usuarios/cambiarEstatus",
            { id_usuario, estatus: true },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + sessionStorage.token,
              },
            }
          )
          .then((res) => {
            if (res.data) {
              Swal.fire(
                "Se ha reactivado usuario",
                "El usuario ya puede acceder a las plataformas",
                "success"
              );
              refresh();
            } else {
              Swal.fire(
                "Algo ocurrió",
                "Comuniquese con el administrador del sistema",
                "error"
              );
            }
          });
      }
    });
  };
  return (
    <Table
      responsive
      size="sm"
      className="table-bordered table-condensed table-hover"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Usuario</th>
          <th>Nombre</th>
          <th>Apellidos</th>
          <th>Estatus</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {users?.map((row) => (
          <tr key={row.id}>
            <td>{row.id}</td>
            <td>{row.username}</td>
            <td>{row.first_name}</td>
            <td>{row.last_name}</td>
            <td>{row.is_active ? "Activo" : "Inactivo"}</td>
            <td>
              {row.is_active && (
                <>
                  <Button
                    className="btn-sm"
                    color="warning"
                    onClick={() => handleModify(row.id)}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                  </Button>{" "}
                  <Button
                    className="btn-sm"
                    color="danger"
                    onClick={() => desactivar(row.id)}
                  >
                    <FontAwesomeIcon icon="fas fa-trash" />
                  </Button>
                </>
              )}
              {!row.is_active && (
                <Button
                  className="btn-sm"
                  color="primary"
                  onClick={() => reactivar(row.id)}
                >
                  <FontAwesomeIcon icon="fas fa-arrow-rotate-right" />
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
