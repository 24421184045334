import QRCode from "react-qr-code";

export const ImprimirQRCode = ({ code, empaque, empacador, margin }) => {
  return (
    <div className={`qr-style ${margin && "mt-1"}`}>
      <label>{`${empacador} - ${empaque}`}</label>
      <QRCode value={code} size={100} />
    </div>
  );
};
