import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { api } from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import Swal from "sweetalert2";
const meses = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const ReportesCargas = () => {
  const [inicio, setInicio] = useState("");
  const [final, setFinal] = useState("");
  const [paletSearch, setPaletSearch] = useState("");
  const [cargas, setCargas] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalBusqueda, setModalBusqueda] = useState(false);
  const [estatusCarga, setEstatusCarga] = useState("0");
  const [propuestaCarga, setPropuestaCarga] = useState([]);
  const [detalleCarga, setDetalleCarga] = useState([]);
  const [banDetalle, setBanDetalle] = useState(true);
  const [resumenPropuesta, setResumenPropuesta] = useState([]);
  const [resumenDetalle, setResumenDetalle] = useState([]);
  const [resumenPalets, setResumenPalets] = useState([]);
  const [listaPalets, setListaPalets] = useState([]);
  window.swal = Swal;
  const toggle = () => {
    setModal(!modal);
  };
  const toggleBusqueda = () => setModalBusqueda(!modalBusqueda);
  const estadosCarga = [
    "cancelado",
    "Sin cargar",
    "Por verificar",
    "Finalizado",
  ];
  const cargarLista = () => {
    Swal.showLoading();
    api
      .post(
        "/api/carga/listaCargasFinalizadas",
        { estatusCarga, inicio, final },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      )
      .then((res) => {
        Swal.close();
        setCargas(res.data);
      });
  };
  const abrirDetalle = (id_carga) => {
    const carga = cargas.find((row) => row.id_carga == id_carga);
    setPropuestaCarga(carga.propuesta);
    setDetalleCarga(carga.detalle);
    setListaPalets(carga.listaPalets);
    setBanDetalle(true);
    toggle();
  };
  const abrirResumen = () => {
    if (cargas.length === 0) {
      Swal.fire("Faltan datos", "No hay cargas en el listado", "error");
    } else {
      let resumenProp = [];
      let resumenDet = [];
      let resumenPal = [];
      for (const carga of cargas) {
        for (const row of carga["detalle"]) {
          let ban = false;
          for (const elem of resumenDet) {
            if (
              elem.cultivo == row.cultivo &&
              elem.atributo == row.atributo &&
              elem.size == row.size &&
              elem.presentacion == row.presentacion &&
              elem.envase == row.envase &&
              elem.bultos == row.bultos &&
              elem.tipo_siembra == row.tipo_siembra
            ) {
              let index = resumenDet.findIndex(
                (dot) =>
                  dot.cultivo == row.cultivo &&
                  dot.atributo == row.atributo &&
                  dot.size == row.size &&
                  dot.presentacion == row.presentacion &&
                  dot.envase == row.envase &&
                  dot.bultos == row.bultos &&
                  dot.tipo_siembra == row.tipo_siembra
              );
              resumenDet[index]["cantidad"] =
                parseInt(resumenDet[index]["cantidad"]) +
                parseInt(row.cantidad);
              ban = true;
              break;
            }
          }
          if (!ban) {
            resumenDet.push({ ...row });
          }
        }
        for (const row of carga["propuesta"]) {
          let ban = false;
          for (const elem of resumenProp) {
            if (
              elem.cultivo == row.cultivo &&
              elem.atributo == row.atributo &&
              elem.size == row.size &&
              elem.presentacion == row.presentacion &&
              elem.envase == row.envase &&
              elem.bultos == row.bultos &&
              elem.tipo_siembra == row.tipo_siembra
            ) {
              let index = resumenProp.findIndex(
                (dot) =>
                  dot.cultivo == row.cultivo &&
                  dot.atributo == row.atributo &&
                  dot.size == row.size &&
                  dot.presentacion == row.presentacion &&
                  dot.envase == row.envase &&
                  dot.bultos == row.bultos &&
                  dot.tipo_siembra == row.tipo_siembra
              );
              resumenProp[index]["cantidad"] += row.cantidad;
              ban = true;
              break;
            }
          }
          if (!ban) {
            resumenProp.push({ ...row });
          }
        }
        for (const row of carga["listaPalets"]) {
          resumenPal.push(row);
        }
      }
      setResumenPropuesta(resumenProp);
      setResumenDetalle(resumenDet);
      setResumenPalets(resumenPal);
      setBanDetalle(false);
      toggle();
    }
  };
  const descargarExcel = () => {
    Swal.fire(
      "Reporte excel",
      "El reporte solo descarga la información de cargas finalizadas o que ya se les añadieron palets",
      "info"
    );
    api
      .post(
        "/api/carga/excelCargas",
        { estatusCarga, inicio, final },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? "Resumen Cargas Enviadas.xls"
            : suggestedFileName;
        FileSaver.saveAs(response.data, effectiveFileName);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ubicarPalet = () => {
    Swal.showLoading();
    api
      .post(
        "/api/carga/buscarPalet",
        { palet: paletSearch },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      )
      .then((res) => {
        Swal.close();
        if (res.data.length == 0) {
          Swal.fire(
            "No se encontraron datos",
            "El palet no se encontró en ninguna carga.",
            "info"
          );
        } else {
          setModalBusqueda(!modalBusqueda);
          setCargas(res.data);
        }
      });
  };
  useEffect(() => {
    let fecha = new Date();
    const fecha_normal =
      fecha.getFullYear() +
      "-" +
      meses[fecha.getMonth()] +
      "-" +
      (fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate());
    setInicio(fecha_normal);
    setFinal(fecha_normal);
  }, []);
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col
              xs="12"
              sm="12"
              md={{ offset: "2", size: "8" }}
              lg={{ offset: "2", size: "8" }}
            >
              <h2 className="text-center">REPORTE DE CARGAS</h2>
            </Col>
            <Col xs="12" sm="12" md="2" lg="2">
              <Button
                className="btn-block"
                color="warning"
                onClick={() => abrirResumen()}
              >
                Resumen
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="2" lg="2">
              <label style={{ fontWeight: "bold" }}>Estatus de carga:</label>
              <Input
                type="select"
                value={estatusCarga}
                onChange={(e) => {
                  setEstatusCarga(e.target.value);
                  setCargas([]);
                }}
                className="form-control"
              >
                <option value="3">Finalizado</option>
                <option value="2">Por verificar</option>
                <option value="1">Sin cargar</option>
                <option value="0">Traer todos</option>
              </Input>
            </Col>
            <Col xs="12" sm="12" md="2" lg="2">
              <label style={{ fontWeight: "bold" }}>Fecha inicio</label>
              <Input
                type="date"
                value={inicio}
                onChange={(e) => setInicio(e.target.value)}
              ></Input>
            </Col>
            <Col xs="12" sm="12" md="2" lg="2">
              <label style={{ fontWeight: "bold" }}>Fecha final</label>
              <Input
                type="date"
                value={final}
                onChange={(e) => setFinal(e.target.value)}
              ></Input>
            </Col>
            <Col xs="12" sm="12" md="2" lg="2">
              <Button
                className="btn-block"
                style={{ marginTop: "31px" }}
                color="primary"
                onClick={() => cargarLista()}
              >
                Buscar
              </Button>
            </Col>
            <Col xs="12" sm="12" md="2" lg="2">
              <Button
                className="btn-block"
                style={{ marginTop: "31px" }}
                color="success"
                onClick={() => descargarExcel()}
              >
                Excel <FontAwesomeIcon icon="fas fa-file-excel" />
              </Button>
            </Col>
            <Col xs="12" sm="12" md="2" lg="2">
              <Button
                className="btn-block"
                style={{ marginTop: "31px" }}
                color="info"
                onClick={() => setModalBusqueda(true)}
              >
                Número de palet{" "}
                <FontAwesomeIcon icon="fas fa-search-location" />
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Table
                responsive
                size="sm"
                className="table-bordered table-condensed table-hover"
              >
                <thead>
                  <tr>
                    <th>Folio</th>
                    <th>Estado</th>
                    <th>No. Carga</th>
                    <th>Fecha</th>
                    <th>Detalle</th>
                    <th>Ver más</th>
                  </tr>
                </thead>
                <tbody>
                  {cargas.map((row) => (
                    <tr key={"tr" + row.id_carga}>
                      <td>{row.id_carga}</td>
                      <td>{estadosCarga[row.estatus]}</td>
                      <td>{row.no_carga}</td>
                      <td>{row.fecha}</td>
                      <td>
                        {row.detalle.map((element) => (
                          <li key={Math.random()}>
                            {element.cantidad} - {element.cultivo}{" "}
                            {element.atributo} {element.size}{" "}
                            {element.presentacion} {element.envase}{" "}
                            {element.bultos} BULTOS {element.tipo_siembra}
                          </li>
                        ))}
                      </td>
                      <td>
                        <Button
                          className="btn-sm"
                          color="info"
                          onClick={() => abrirDetalle(row.id_carga)}
                        >
                          <FontAwesomeIcon icon="fas fa-eye" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader>Detalle de carga</ModalHeader>
            {banDetalle && (
              <ModalBody>
                <Row>
                  <Col xs="12">
                    <h4>Propuesta realizada para esta carga</h4>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="12">
                    <Table
                      responsive
                      size="sm"
                      className="table-bordered table-condensed table-hover"
                    >
                      <thead>
                        <tr>
                          <td>Producto</td>
                          <td>Cantidad</td>
                        </tr>
                      </thead>
                      <tbody>
                        {propuestaCarga.map((row) => (
                          <tr key={Math.random()}>
                            <td>
                              {row.cultivo} {row.atributo} {row.size}{" "}
                              {row.presentacion} {row.envase} {row.bultos}{" "}
                              BULTOS {row.tipo_siembra}
                            </td>
                            <td>{row.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <h4>Producto enviado en la carga</h4>
                  </Col>
                  <Col xs="12">
                    <Table
                      responsive
                      size="sm"
                      className="table-bordered table-condensed table-hover"
                    >
                      <thead>
                        <tr>
                          <td>Producto</td>
                          <td>Cantidad</td>
                        </tr>
                      </thead>
                      <tbody>
                        {detalleCarga.map((row) => (
                          <tr key={Math.random()}>
                            <td>
                              {row.cultivo} {row.atributo} {row.size}{" "}
                              {row.presentacion} {row.envase} {row.bultos}{" "}
                              BULTOS {row.tipo_siembra}
                            </td>
                            <td>{row.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <h4>Listado de palets</h4>
                  </Col>
                  <Col xs="12">
                    <Table
                      responsive
                      size="sm"
                      className="table-bordered table-condensed table-hover"
                    >
                      <thead>
                        <tr>
                          <td>Palet</td>
                          <td>Producto</td>
                        </tr>
                      </thead>
                      <tbody>
                        {listaPalets.map((row) => (
                          <tr key={Math.random()}>
                            <td>{row.codigo_palet}</td>
                            <td>
                              {row.cultivo} {row.atributo} {row.size}{" "}
                              {row.presentacion} {row.envase} {row.bultos}{" "}
                              BULTOS {row.tipo_siembra}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </ModalBody>
            )}
            {!banDetalle && (
              <ModalBody>
                <Row>
                  <Col xs="12">
                    <h4>Producto total propuesto para carga</h4>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="12">
                    <Table
                      responsive
                      size="sm"
                      className="table-bordered table-condensed table-hover"
                    >
                      <thead>
                        <tr>
                          <td>Producto</td>
                          <td>Cantidad</td>
                        </tr>
                      </thead>
                      <tbody>
                        {resumenPropuesta.map((row) => (
                          <tr key={Math.random()}>
                            <td>
                              {row.cultivo} {row.atributo} {row.size}{" "}
                              {row.presentacion} {row.envase} {row.bultos}{" "}
                              BULTOS {row.tipo_siembra}
                            </td>
                            <td>{row.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <h4>Total de productos que se cargaron</h4>
                  </Col>
                  <Col xs="12">
                    <Table
                      responsive
                      size="sm"
                      className="table-bordered table-condensed table-hover"
                    >
                      <thead>
                        <tr>
                          <td>Producto</td>
                          <td>Cantidad</td>
                        </tr>
                      </thead>
                      <tbody>
                        {resumenDetalle.map((row) => (
                          <tr key={Math.random()}>
                            <td>
                              {row.cultivo} {row.atributo} {row.size}{" "}
                              {row.presentacion} {row.envase} {row.bultos}{" "}
                              BULTOS {row.tipo_siembra}
                            </td>
                            <td>{row.cantidad}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <h4>Total de productos que se cargaron</h4>
                  </Col>
                  <Col xs="12">
                    <Table
                      responsive
                      size="sm"
                      className="table-bordered table-condensed table-hover"
                    >
                      <thead>
                        <tr>
                          <td>Palet</td>
                          <td>Producto</td>
                        </tr>
                      </thead>
                      <tbody>
                        {resumenPalets.map((row) => (
                          <tr key={Math.random()}>
                            <td>{row.codigo_palet}</td>
                            <td>
                              {row.cultivo} {row.atributo} {row.size}{" "}
                              {row.presentacion} {row.envase} {row.bultos}{" "}
                              BULTOS {row.tipo_siembra}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </ModalBody>
            )}
            <ModalFooter>
              <Button color="danger" onClick={toggle}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={modalBusqueda} toggle={toggleBusqueda} size="lg">
            <ModalHeader>Ubicar número de palet</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs="12" sm="12" md="9" lg="9">
                  <label style={{ fontWeight: "bold" }}>Número de palet</label>
                  <Input
                    placeholder="Ingresar número de palet"
                    value={paletSearch}
                    onChange={(e) => setPaletSearch(e.target.value)}
                  ></Input>
                </Col>
                <Col xs="12" sm="12" md="3" lg="3">
                  <Button
                    className="btn-block"
                    color="primary"
                    style={{ marginTop: "31px" }}
                    onClick={() => ubicarPalet()}
                  >
                    <FontAwesomeIcon icon="fas fa-search" />
                  </Button>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleBusqueda}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </>
  );
};
