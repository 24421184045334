import { useEffect, useState } from "react";

const useCountdown = (targetDate) => {
  const targetTime = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(targetTime - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(targetTime - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetTime]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  let hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  return [days, hours, minutes, seconds];
};

export { useCountdown };
