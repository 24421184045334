import { api } from "../../config/api";
import axios from "axios";
import Swal from "sweetalert2";

export const useAddUser = () => {
  const addUser = async (values) => {
    try {
      await api.post("/api/administracion/usuarios/guardarDatos", values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      });
      Swal.fire({
        icon: "success",
        title: "Usuario registrado",
        timer: 1500,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        Swal.fire("Algo ocurrió", error.response.data.msg, "error");
      } else {
        Swal.fire("Algo ocurrió", "", "error");
      }
    }
  };

  const updateUser = async (values) => {
    try {
      await api.put("/api/administracion/usuarios/guardarDatos", values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      });
      Swal.fire({
        icon: "success",
        title: "Usuario registrado",
        timer: 1500,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        Swal.fire("Algo ocurrió", error.response.data.msg, "error");
      } else {
        Swal.fire("Algo ocurrió", "", "error");
      }
    }
  };

  return { addUser, updateUser };
};
