/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "reactstrap";
import { UserTable } from "../components/User.Table";
import { useUser } from "../hooks/useUser";
import { Text } from "../components/Text";
import { AddUser } from "../components/AddUser";
import { UserDetails } from "../components/User.Details";

export const AdminUsuarios = () => {
  const { userList, fetchUser } = useUser();
  const [showAdd, setShowAdd] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);

  const showAddUer = () => {
    setShowAdd(!showAdd);
    setSelectedUserId(0);
  };

  const modificar = (id_usuario) => {
    setSelectedUserId(id_usuario);
    setShowAdd(false);
  };

  const cancelUpdate = () => {
    setSelectedUserId(0);
    setShowAdd(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center">
        <Text>Usuarios</Text>
        <button
          className="rounded-full bg-blue-500 h-10 w-10 hover:bg-blue-600"
          onClick={showAddUer}
        >
          <FontAwesomeIcon icon="fa-solid fa-plus" className="text-white" />
        </button>
      </div>
      <br />
      <Row>
        <Col>
          <UserTable
            users={userList}
            handleModify={modificar}
            refresh={fetchUser}
          />
        </Col>
        {showAdd && (
          <Col className="border rounded-sm p-3 flex flex-col gap-5">
            <Text>Nuevo Usuario</Text>
            <AddUser onAdded={fetchUser} />
          </Col>
        )}
        {selectedUserId !== 0 && (
          <Col className="border rounded-sm p-3 flex flex-col gap-5">
            <UserDetails
              user={userList.find((user) => user.id === selectedUserId)}
              onUpdate={fetchUser}
              onCancel={cancelUpdate}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
