/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import { api } from "../../../config/api";
// import { socket } from "../../../config/socket";
import { useEmpaque } from "../../hooks/useEmpaque";
import { useCultivo } from "../../hooks/useCultivo";

function setStyleBorder(numNomina) {
  return numNomina
    ? {
        padding: "5px",
        margin: "25px",
        border: "2px solid",
      }
    : {
        margin: "25px",
      };
}

const EscaneoEmpaque = () => {
  // const { cultivos } = useCultivo();
  const input = useRef();
  const [folio, setFolio] = useState("");
  const [empleadosCantidad, setEmpleadosCantidad] = useState([]);
  const [numNomina, setNumNomina] = useState(null);
  const [encabezados, setEncabezados] = useState([]);
  const [cultivo, setCultivo] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const { empaques, total } = useEmpaque(0);

  const onSubmit = () => {
    api
      .post(
        "api/empaque/empleados/",
        {
          folio: folio,
          id_cultivo: user.cultivo,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      )
      .then((response) => {
        updateTable(response.data);
        console.log(response.data);
        // socket.emit(`scan/${user.cultivo_name}`, user.cultivo);
      })
      .catch((error) => {})
      .finally(() => {
        setFolio("");
      });
  };

  const updateTable = (data) => {
    const empaque_data = empaques.find(
      (empaque) => empaque.empaque === data.empaque
    );

    const empaque_exists = encabezados.some(
      (item) => item.empaque === data.empaque
    );
    const cultivo_exists = cultivo.some((item) => item === data.cultivo);

    if (!empaque_exists) {
      setEncabezados([
        ...encabezados,
        { empaque: empaque_data.empaque, cultivo: empaque_data.cultivo },
      ]);
    }

    if (!cultivo_exists) {
      setCultivo([...cultivo, empaque_data.cultivo]);
    }

    const empacador = {
      num_nomina: data.empleado.num_nomina,
      nombre: `${data.empleado.nombre} ${data.empleado.paterno} ${data.empleado.materno}`,
      empacador: data.empleado.num_empacador,
      ...total,
      total: 0,
    };

    empacador[empaque_data.empaque] = 1;
    empacador.total = 1;

    const index = empleadosCantidad.findIndex(
      (item) => item.num_nomina === data.empleado.num_nomina
    );

    if (index >= 0) {
      empleadosCantidad[index][empaque_data.empaque] += 1;
      empleadosCantidad[index].total += 1;
      setNumNomina(data.empleado.num_nomina);
      setTimeout(() => {
        setNumNomina(null);
      }, 1000);
    } else {
      empleadosCantidad.push(empacador);
    }

    empleadosCantidad.sort((a, b) => b.total - a.total);
  };

  useEffect(() => {
    console.log(encabezados);
    console.log(cultivo);
  }, [encabezados, cultivo]);

  useEffect(() => {
    const getEscaneo = async () => {
      try {
        const response = await api.get(`api/empaque/escaneo/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        });
        console.log(response.data);
        setEmpleadosCantidad(response.data.empacadores ?? []);
        setEncabezados(response.data.empaques);
        setCultivo(response.data.cultivo);
      } catch (error) {}
    };
    getEscaneo();
  }, []);

  useEffect(() => {
    input.current.focus();
    // socket.connect();
    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  return (
    <div style={setStyleBorder(numNomina)}>
      <Row>
        <Col>
          <input
            className="form-control"
            ref={input}
            value={folio}
            onChange={(event) => {
              setFolio(event.target.value);
            }}
            autoFocus={true}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                onSubmit();
              }
            }}
            onBlur={() => {
              input.current.focus();
            }}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                {cultivo?.map((item) => (
                  <th
                    key={item}
                    className="text-center"
                    colSpan={
                      encabezados.filter((value) => value.cultivo === item)
                        .length
                    }
                  >
                    {item}
                  </th>
                ))}
                <th></th>
              </tr>
              <tr>
                <th style={{ width: "100px" }}>Empacador</th>
                <th style={{ width: "100px" }}>Nómina</th>
                <th style={{ width: "500px" }}>Nombre</th>
                {cultivo.map((item) =>
                  encabezados
                    .filter((value) => value.cultivo === item)
                    .map((empaque) => {
                      return <th key={empaque.empaque}>{empaque.empaque}</th>;
                    })
                )}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {empleadosCantidad?.map((empleado) => (
                <tr
                  className={
                    numNomina === empleado.num_nomina ? "table-success" : ""
                  }
                  key={empleado.num_nomina}
                  style={{ fontSize: "20px" }}
                >
                  <td>{empleado.empacador}</td>
                  <td>{empleado.num_nomina}</td>
                  <td>{empleado.nombre}</td>
                  {cultivo.map((item) =>
                    encabezados
                      .filter((value) => value.cultivo === item)
                      .map((empaque) => (
                        <td key={empaque.empaque}>
                          {empleado[empaque.empaque]}
                        </td>
                      ))
                  )}
                  <td>{empleado.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default EscaneoEmpaque;
