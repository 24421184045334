import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserProvider } from "./config/UserProvider";

import { LoginRouter } from "./components/LoginRouter";

function App() {
  return (
    <UserProvider>
      <LoginRouter />
    </UserProvider>
  );
}

export default App;
