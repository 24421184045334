/* eslint-disable react-hooks/exhaustive-deps */
import { BarList, Card } from "@tremor/react";
import { useRef, useEffect } from "react";

export function Chart({ data, title }) {
  const containerRef = useRef();
  const scrollStep = 1; // Ajusta la velocidad del scroll hacia abajo

  const startSmoothScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;

      if (isAtBottom) {
        // Si estamos al final, no hacemos nada (scroll hacia abajo ya terminado)
        handleScrollToTop(container);
      } else {
        // Si no estamos al final, hacemos scroll hacia abajo
        scrollToBottom(container);
      }
    }
  };

  const scrollToBottom = (container) => {
    if (container.scrollTop < container.scrollHeight - container.clientHeight) {
      container.scrollTop += scrollStep;
      setTimeout(() => scrollToBottom(container), 50); // Ajusta el tiempo para controlar la velocidad
    }
  };

  const handleScrollToTop = (container) => {
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "instant", // Scroll instantáneo hacia arriba
      });
    }
  };

  // Lógica para iniciar el scroll suave hacia abajo en intervalos
  useEffect(() => {
    const interval = setInterval(startSmoothScroll, 5000); // Cambia el tiempo aquí según tus necesidades (5000 milisegundos = 5 segundos)
    return () => clearInterval(interval);
  }, []);

  return (
    <Card className="mx-auto">
      <h3 className="text-tremor-title text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">
        {title}
      </h3>
      <br />
      <div className="overflow-y-auto max-h-96 p-2" ref={containerRef}>
        <BarList
          data={data}
          className="mt-2"
          showAnimation={true}
          color="indigo"
        />
      </div>
      <style>{`
        a, p {
          font-weight: bold;
        }
      `}</style>
    </Card>
  );
}
