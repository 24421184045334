import { useEffect, useState } from "react";
import { api } from "../../config/api";

export const useCultivos = () => {
  const [cultivos, setCultivos] = useState([]);
  const [loadig, setLoading] = useState(false);

  useEffect(() => {
    const fetchCultivos = async () => {
      setLoading(true);
      try {
        const response = await api.get("api/cosecha/cosechasList", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        });

        setLoading(false);
        setCultivos(response.data);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchCultivos();
  }, []);

  return { cultivos, loadig };
};
