/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { api } from "../../../config/api";
import { Row, Col, Table } from "reactstrap";
import { fireLoading, displayRequestError } from "../../utils/request.error";
import Swal from "sweetalert2";
import FileSaver from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function formatearComoMoneda(numero) {
  return numero.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

const HojaDeCarga = () => {
  const [filtro, setFiltro] = useState("");
  const [data, setData] = useState([]);
  const [totales, setTotales] = useState({});

  const getReporte = async () => {
    const xfiltro = filtro === "" ? "" : `?manifiesto=${filtro}`;
    fireLoading({ title: "", body: "" });
    try {
      const response = await api.get(`api/inventario/reporte/${xfiltro}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      });
      setData(response.data.reporte);
      setTotales(response.data.totales);
      Swal.close();
    } catch (error) {
      displayRequestError(error);
    }
  };

  const downloadReporte = async () => {
    api
      .post(
        "api/inventario/reporte/",
        { manifiesto: filtro === "" ? null : filtro },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const effectiveFileName = `HOJA DE CARGAS.xlsx`;
        FileSaver.saveAs(response.data, effectiveFileName);
      })
      .catch((error) => {
        displayRequestError(error);
      });
  };

  useEffect(() => {
    getReporte();
  }, []);

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <input
              value={filtro}
              onChange={(e) => {
                setFiltro(e.target.value);
              }}
              className="form-control"
              placeholder="No. de manifiesto"
            />
          </Col>
          <Col>
            <Row>
              <Col md={2}>
                <button className="btn btn-primary" onClick={getReporte}>
                  Buscar
                </button>
              </Col>
              <Col md={2}>
                <button
                  className="btn btn-outline-success"
                  onClick={downloadReporte}
                >
                  <FontAwesomeIcon icon="fa-solid fa-file-excel" />
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <div className="row">
          <Col>
            <Table
              responsive
              size="sm"
              className="table-bordered table-condensed table-hover"
            >
              <thead>
                <tr>
                  <th>MANIFIESTO</th>
                  <th>VARIEDAD</th>
                  <th>NO. PRODUCTOR</th>
                  <th>PRODUCTOR</th>
                  <th>TAMAÑOS</th>
                  <th>TARIMAS</th>
                  <th>TOTAL KGS TARIMAS</th>
                  <th>COSTALES</th>
                  <th>TOTAL KGS</th>
                  <th>PESO NETO KILOS</th>
                  <th>PRECIO POR KILO</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.manifiesto}</td>
                    <td>{item.variedad}</td>
                    <td>{item.numero_productor}</td>
                    <td>{item.productor}</td>
                    <td>{item.tamanio}</td>
                    <td>{item.tarimas}</td>
                    <td>{item.total_kgs_tarimas}</td>
                    <td>{item.costales}</td>
                    <td>{item.total_kgs_costales}</td>
                    <td>{item.peso_neto_kilos}</td>
                    <td>{formatearComoMoneda(item.precio_por_kilo)}</td>
                    <td>{formatearComoMoneda(item.total)}</td>
                  </tr>
                ))}
                <tr style={{ fontWeight: "bold" }}>
                  <td colSpan={5}>TOTALES</td>
                  <td>{totales.tarimas}</td>
                  <td>{totales.kg_tarimas}</td>
                  <td>{totales.costales}</td>
                  <td>{totales.total_kgs}</td>
                  <td>{totales.peso_neto}</td>
                  <td></td>
                  <td>{formatearComoMoneda(totales.total ?? 0)}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </div>
      </Col>
    </Row>
  );
};

export default HojaDeCarga;
