import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { api } from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Swal from "sweetalert2";

export const AdminCosechas = () => {
    const [idSelected, setIdSelected] = useState(0);
    const [tiempoHoras, setTiempoHoras] = useState(0);
    const [tiempoMinutos, setTiempoMinutos] = useState(0);
    const [cosechaList, setCosechaList] = useState([]);
    const [tiposPreenfrios, setTiposPreenfrios] = useState([]);
    const [tipo, setTipo] = useState(0);
    const [tipoText, setTipoText] = useState('');
    const [newTipo, setNewTipo] = useState('');
    const [ciclos, setCiclos] = useState([]);
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const modalCiclos = () => {
        if(idSelected === '0'){
            Swal.fire("Faltan datos","Debes seleccionar una cosecha","warning");
        }else{
            setTiempoHoras(0)
            setTiempoMinutos(0)
            toggle()
        }
    }
    const guardarCiclo = () => {
        if(tiempoHoras <= 0 && tiempoMinutos <= 0){
            Swal.fire("Faltan datos","Debes ingresar el tiempo de duración del ciclo","error");
        }else{
            let data = {
                id_tipo: tipo,
                ciclo: ciclos.length+1,
                duracion : tiempoHoras*60*60+tiempoMinutos*60
            }
            api.post('/api/preenfrio/guardarCicloPreenfrio', data, {headers:{ 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token }}).then(res => {
                if(res.data){
                    Swal.fire("Se guardó con éxito","Registro exitoso","success");
                    toggle();
                    consultarCiclosPreenfrio(tipo);
                }else{
                    Swal.fire('Algo ocurrió','Comuniquese con el administrador del sistema','error');
                    toggle();
                }
            })
        }
        
    }
    const seleccionarCosecha = (id_cosecha) => {
        setIdSelected(id_cosecha);
        setTipo(0);
        setTipoText('');
        setCiclos([]);
        consultarTiposPreenfrio(id_cosecha);
    }
    const consultarTiposPreenfrio = (id_cosecha) => {
        if(id_cosecha != '0'){
            api.post('/api/preenfrio/listadoTiposPreenfrios', {id_cosecha},{headers:{ 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token }}).then(res => {
                setTiposPreenfrios(res.data);
            });
        }else{
            setTiposPreenfrios([]);
        }
    }
    const agregarTipoPreefrio = () => {
        const data = { preenfrio: newTipo, cosecha: idSelected}
        if(data.preenfrio.trim() == ''){
            Swal.fire("faltan datos","Debes ingresar el nombre del nuevo preenfrio","error");
        }else{
            if(data.cosecha == '0'){
                Swal.fire("Faltan datos","Debes seleccionar la cosecha destino","error");
            }else{
                api.post('/api/preenfrio/guardarTipoPreenfrio', data, {headers:{ 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token }}).then(res => {
                    if(res.data){
                        Swal.fire("Se guardó con éxito","","success");
                        consultarTiposPreenfrio(data.cosecha)
                    }else{
                        Swal.fire("Algo ocurrió","Comunicate con el administrador del sistema","error");
                    }
                });
            }
        }
    }
    const selectTipoPreenfrio = (id_tipo, nombre) => {
        setTipo(id_tipo); 
        setTipoText(nombre);
        consultarCiclosPreenfrio(id_tipo);
    }
    const consultarCiclosPreenfrio = (id_tipo) => {
        api.post('/api/preenfrio/preenfrioCiclos', {id_tipo}, {headers:{ 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token }}).then(res => {
            setCiclos(res.data);
        });
    }
    const eliminarCiclo = (id_ciclo) => {
        Swal.fire({
            title: 'Eliminar ciclo',
            text: "¿Esta seguro de realizar esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrarlo!'
        }).then((result) => {
            if (result.isConfirmed) {
                api.post('/api/preenfrio/borrarCiclo', {id_ciclo}, {headers:{ 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token }} ).then(res => {
                    if(res.data){
                        Swal.fire('Deleted!','Your file has been deleted.','success')
                        consultarCiclosPreenfrio(tipo);
                    }else{
                        Swal.fire("Algo ocurrió","Comuniquese con el administrador del sistema","error");
                    }
                });
            }
        });
    }
    useEffect(() => {
        api.get('/api/cosecha/cosechasList',{headers:{'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token} }).then(res => {
            setCosechaList(res.data);
        });
    },[]);
    return (
    <>
        <Row>
            <Col xs="12">
                <Row>
                    <Col xs="12">
                        <h2 className="text-center">Cosechas</h2>
                    </Col>
                </Row><br/>
                <Row>
                    <Col xs="6">
                        <Row>   
                            <Col xs="12" sm="12" md="12" lg="12">
                                <label style={{fontWeight:"bold"}}>Cosecha</label>
                                <Input className="form-control" type="select" value={idSelected} onChange={(e) => {seleccionarCosecha(e.target.value)}} >
                                    <option value="0">Seleccionar cosecha...</option>
                                    {
                                        cosechaList.map(row => (
                                            <option key={row.id_cosecha} value={row.id_cosecha}>{row.cosecha}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6">
                                <label style={{ fontWeight:'bold' }}>Nuevo Preenfrio:</label>
                                <Input placeholder="Nuevo preenfrio" value={newTipo} onChange={(e) => setNewTipo(e.target.value)}></Input>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                <Button className="btn-block" style={{marginTop:"31px"}} color="primary" onClick={() => agregarTipoPreefrio()}>Nuevo preenfrio</Button>
                            </Col>
                        </Row>
                        <br/>
                        <Row>        
                            <Col xs="12">
                                <Table bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Tipo preenfrio</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>
                                    <tbody>
                                        {
                                            tiposPreenfrios.map(row => (
                                                <tr key={row.id_tipo}>
                                                    <td>{row.nombre}</td>
                                                    <td><Button className="btn-sm"  color="warning" onClick={() => {selectTipoPreenfrio(row.id_tipo, row.nombre)}}><FontAwesomeIcon icon="fas fa-arrow-circle-right" /></Button></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="6">
                        <Row>
                            <Col xs="6">
                                <label style={{fontWeight:"bold"}}>Cosecha seleccionada</label>
                                <Input placeholder="Nombre de la cosecha" value={tipoText} disabled></Input>
                            </Col>
                            <Col xs="6">
                                <Button className="btn btn-block" style={{marginTop:"31px"}} onClick={() =>{modalCiclos()}} color="primary">Agregar ciclo</Button>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xs="12">
                                <Table bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Ciclo</th>
                                            <th>Duración</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ciclos.map(row => (
                                                <tr key={'trCiclo'+row.id_config}>
                                                    <td>{row.ciclo}</td>
                                                    <td>{parseInt(row.duracion/60/60)} hrs {(row.duracion/60)%60} min</td>
                                                    <td>
                                                    {
                                                        row.ciclo === ciclos.length ?
                                                        <Button className="btn-sm"  color="danger" onClick={() => {eliminarCiclo(row.id_config)}}><FontAwesomeIcon icon="fas fa-trash" /></Button>
                                                        : <Button className="btn-sm"  color="danger" disabled><FontAwesomeIcon icon="fas fa-trash" /></Button>
                                                    }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>Agregar nuevo ciclo</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs="12">
                        <label style={{fontWeight:"bold"}}>Nuevo ciclo: {ciclos.length+1}</label>
                    </Col>
                    <Col xs="12">
                        <label style={{fontWeight:'bold'}}>
                            Tiempo de duración:
                        </label>
                    </Col>
                    <Col xs="6">
                        <label>Horas:</label>
                        <Input type="number" min="0" value={tiempoHoras} onChange={(e) => {setTiempoHoras(e.target.value)}} placeholder="Horas"></Input>
                    </Col>
                    <Col xs="6">
                        <label>Minutos:</label>
                        <Input type="number" min="0" value={tiempoMinutos} onChange={(e) => {setTiempoMinutos(e.target.value)}} placeholder="Minutos"></Input>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={guardarCiclo}>
                    Agregar
                </Button>{' '}
                <Button color="danger" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    </>);
}