import { object, string, number, boolean } from "yup";

export const userSchema = object({
  first_name: string().required(),
  last_name: string().required(),
  cultivo: number().required(),
  username: string().required(),
  editar: boolean(),
  id_usuario: number().when("editar", ([editar], schema) =>
    editar ? schema.moreThan(0).required() : schema
  ),
  password: string().when("editar", ([editar], schema) =>
    !editar ? schema.required() : schema
  ),
  email: string(),
});

export const UserSchema = object({
  first_name: string().required(),
  last_name: string().required(),
  username: string().required(),
  password: string().required(),
  email: string(),
});
