import Swal from "sweetalert2";
import axios from "axios";

const fireSwalError = ({ title, body }) => {
  Swal.fire({
    icon: "error",
    title: title,
    text: body,
  });
};

export const fireLoading = ({ title, body }) => {
  Swal.fire({
    title: title,
    text: body,
    allowOutsideClick: false,
    showCancelButton: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
};

export const fireSuccess = ({ title, body }) => {
  if (Swal.isLoading()) {
    Swal.hideLoading();
  }
  Swal.fire({
    icon: "success",
    title: title,
    text: body,
    timer: 1500,
  });
};

export const displayRequestError = (error) => {
  if (!axios.isAxiosError(error)) {
    let noAxiosMsg = {
      title: "Error en la solicitud",
      body: "La solicitud no pudo ser completada",
    };
    fireSwalError(noAxiosMsg);
    return;
  }

  if (error.code === "ERR_NETWORK") {
    fireSwalError({
      title: "Error de conexión",
      body: "Favor de validar",
    });
    return;
  }

  if (error.response?.status === 401) {
    let unauthorizedMsg = {
      title: "Credenciales incorrectas",
      body: "El usuario y la contraseña no coinciden.",
    };

    fireSwalError(unauthorizedMsg);
    return;
  }

  if (error.response?.status === 400) {
    let unauthorizedMsg = {
      title: error.response.data.msg,
      body: "",
    };

    fireSwalError(unauthorizedMsg);
    return;
  }

  fireSwalError({
    title: "Error en el servidor",
    body: "Hay un problema en el servidor. Intenta más tarde.",
  });
};
