import { useState } from "react";
import { api } from "../../config/api";

export const useUser = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await api.get("/api/administracion/get_users", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      });
      setUserList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return { userList, loading, fetchUser };
};
