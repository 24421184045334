import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, Table } from "reactstrap";
import Swal from "sweetalert2";
import { api } from "../../config/api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PreenfrioCamaras = () => {
    const [camaras, setCamaras] = useState([]);
    const [camara, setCamara] = useState("");
    const [camaraText, setCamaraText] = useState("Cámara:");
    const [camaraSelected, setCamaraSelected] = useState(0);
    const guardarCamara = () => {
        let valor = camara.trim();
        if(valor === ""){
            Swal.fire("Debes ingresar el nombre de la camara","","error");
        }else{
            api.post('/api/preenfrio/guardarCamara',{id_camara:camaraSelected, camara}, { headers:{ 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token } }).then(res => {
                if(res.data.result){
                    Swal.fire("Se guardó con éxito","","success");
                    listaCamaras();
                    cancelarRegistro();
                }else{
                    Swal.fire("Algo ocurrió",res.data.msg,"error");
                }
            });
        }
    }
    const cancelarRegistro = () => {
        setCamaraSelected(0);
        setCamara("");
        setCamaraText("Cámara:")
    }
    const listaCamaras = () => {
        api.get('/api/preenfrio/listaCamaras', { headers:{ 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token } }).then(res => {
            setCamaras(res.data);
        });
    }
    const habilitarEdicion = (id_camara) => {
        let camara = camaras.find(row => row.id_camara === id_camara);
        setCamaraSelected(camara.id_camara);
        setCamara(camara.camara);
        setCamaraText("Cámara: (editanto '"+camara.camara+"')");
    }
    const bajaCamara = (id_camara) => {
        let camara = camaras.find(row => row.id_camara === id_camara);
        Swal.fire({
            title: '¿Desea dar de baja?',
            text: "la camara '"+camara.camara+"' Ya no sera accesible desde la app movil",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, dar de baja'
        }).then((result) => {
            if (result.isConfirmed) {
                api.post('/api/preenfrio/estatusCamara', {id_camara, estatus:false}, { headers:{ 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token } }).then(res => {
                    if(res.data){
                        Swal.fire("La acción se realizó con éxito","","success");
                        listaCamaras();
                        cancelarRegistro();
                    }else{
                        Swal.fire("Algo ocurrió","Comunicate con el administrador del sistema","error");
                    }
                });
            }
        });
    }
    const altaCamara = (id_camara) => {
        let camara = camaras.find(row => row.id_camara === id_camara);
        Swal.fire({
            title: '¿Desea reactivar?',
            text: "la camara '"+camara.camara+"' Ya no sera accesible desde la app movil",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, reactivar'
        }).then((result) => {
            if (result.isConfirmed) {
                api.post('/api/preenfrio/estatusCamara', {id_camara, estatus:true}, { headers:{ 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token } }).then(res => {
                    if(res.data){
                        Swal.fire("La acción se realizó con éxito","","success");
                        listaCamaras();
                        cancelarRegistro();
                    }else{
                        Swal.fire("Algo ocurrió","Comunicate con el administrador del sistema","error");
                    }
                });
            }
        });
    }
    useEffect(() => {
        listaCamaras();
    },[]);
    return (
        <Row>
            <Col xs="12">
                <Row>
                    <Col xs="12">
                        <h2 className="text-center">CÁMARAS DE PREENFRIO</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md={{offset:2, size:8}} lg={{offset:3, size:6}}>
                        <Row>
                            <Col xs="12" sm="12" md="8" lg="8">
                                <label style={{fontWeight:'bold'}}>{camaraText}</label>
                                <Input placeholder="Nombre de la cámara" value={camara} onChange={(e) => setCamara(e.target.value)}></Input>
                            </Col>
                            <Col xs="6" sm="6" md="2" lg="2">
                                <Button style={{marginTop:'31px'}} className="btn-block" color="primary" onClick={() => guardarCamara()}><FontAwesomeIcon icon="fas fa-save" /></Button>
                            </Col>
                            <Col xs="6" sm="6" md="2" lg="2">
                                <Button style={{marginTop:'31px'}} className="btn-block" color="danger" onClick={() => cancelarRegistro()}><FontAwesomeIcon icon="fas fa-ban" /></Button>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Table responsive size="sm" className="table-bordered table-condensed table-hover">
                                    <thead>
                                        <tr>
                                            <th>Cámara</th>
                                            <th>Estatus</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            camaras.map(row => (
                                                <tr key={row.id_camara}>
                                                    <td>{row.camara}</td>
                                                    <td>{row.estatus == 1 ? "Activo" : "Inactivo"}</td>
                                                    {
                                                        row.estatus == 1 ? 
                                                        <td>
                                                            <Button className="btn-sm" onClick={() => habilitarEdicion(row.id_camara)} color="warning"><FontAwesomeIcon icon="fas fa-edit"/></Button>
                                                            {" "}
                                                            <Button className="btn-sm" onClick={() => bajaCamara(row.id_camara)} color="danger"><FontAwesomeIcon icon="fas fa-trash-alt" /></Button>
                                                        </td>
                                                        :
                                                        <td>
                                                            <Button className="btn-sm" onClick={() => altaCamara(row.id_camara)} color="primary"><FontAwesomeIcon icon="fas fa-redo" /></Button>
                                                        </td>
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}