/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useInventarioAgrupado } from "../../hooks/useInventarioAgrupado";
import { GenericList } from "../../components/Generic.List";
import { LargeListButton } from "../../components/Large.List.Button";
import { PaletAgrupadoDetail } from "../../components/Palet.Agrupado.Detail";
import { Row, Col, Card } from "reactstrap";
import { api } from "../../../config/api";
import { number } from "yup";
import {
  displayRequestError,
  fireLoading,
  fireSuccess,
} from "../../utils/request.error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

const pesoSchema = number().moreThan(0).required();

const EmptyList = () => {
  return (
    <Row style={{ margin: "20px" }}>
      <Col className="text-center">
        <FontAwesomeIcon
          icon="fa-solid fa-box-open"
          size="2xl"
          style={{ color: "#c9c9c9", fontSize: "150px" }}
        />
        <h5 style={{ color: "rgba(0,0,0,0.5)", fontSize: "20px" }}>
          SIN REGISTROS
        </h5>
      </Col>
    </Row>
  );
};

const InventarioAgrupado = () => {
  const [idGrupo, setIdGrupo] = useState(0);
  const { inventario, detail, getInventario } = useInventarioAgrupado(idGrupo);
  const [filter, setFilter] = useState("");
  const [peso, setPeso] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inventarioOptions, setInventarioOptions] = useState([]);

  const handleChangeFilter = (e) => {
    const filtro = e.target.value.toLowerCase();
    setFilter(filtro);

    if (filtro === "") {
      setInventarioOptions(inventario);
      return;
    }

    const options = inventario.filter((item) => {
      const comentario = item.comentario.toLowerCase();
      return comentario.includes(filtro);
    });

    setInventarioOptions(options);
  };

  const handleClickButton = (id_grupo) => {
    setIdGrupo(id_grupo);
  };

  const handlePesoChangeHandler = async (e) => {
    setPeso(e.target.value);
  };

  const submitPeso = async () => {
    setError(false);
    const isValid = await pesoSchema.isValid(peso);
    if (!isValid) {
      setError(true);
      return;
    }
    setIsLoading(true);
    try {
      await api.put(
        "api/inventario/inventario-agrupado/",
        {
          peso: peso,
          id_grupo: idGrupo,
          db: "NUEZ",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      );
      fireSuccess({ title: "Peso actualizado", body: "" });
      setIdGrupo(0);
      setIsLoading(false);
      setPeso("");
      getInventario();
    } catch (error) {
      setIsLoading(false);
      displayRequestError(error);
    }
  };

  const fetchInventario = async () => {
    setIdGrupo(0);
    fireLoading({ title: "", body: "" });
    await getInventario();
    Swal.close();
  };

  useEffect(() => {
    setInventarioOptions(inventario);
  }, [inventario]);

  useEffect(() => {
    fetchInventario();
  }, []);

  return (
    <div>
      <Row>
        <Col className="text-center">
          <h3>INVENTARIO</h3>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <GenericList
            data={inventarioOptions}
            renderItem={(item, index) => (
              <LargeListButton
                title={item.comentario}
                onClick={() => handleClickButton(item.id_grpo)}
                style={
                  idGrupo === item.id_grpo ? { backgroundColor: "#ededed" } : {}
                }
              />
            )}
            emptyListComponent={<EmptyList />}
          >
            <Row>
              <Col sm={2} md={6}>
                <input
                  className="form-control"
                  value={filter}
                  onChange={handleChangeFilter}
                  placeholder="Filtro"
                />
              </Col>
            </Row>
          </GenericList>
        </Col>
        {idGrupo !== 0 && (
          <Col>
            <Card style={{ padding: "10px" }}>
              <br />
              <Row>
                <Col>
                  <input
                    className="form-control"
                    value={peso}
                    onChange={handlePesoChangeHandler}
                    type="number"
                    style={error ? { borderColor: "red" } : {}}
                    min={0}
                    placeholder="Peso tarimas"
                  />
                </Col>
                <Col>
                  <button
                    className="btn btn-primary"
                    onClick={submitPeso}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                    ) : (
                      "Ingresar"
                    )}
                  </button>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <p
                    style={{ color: "rgba(0,0,0,0.5)" }}
                  >{`${detail.length} REGISTROS`}</p>
                </Col>
              </Row>
              <Row>
                <PaletAgrupadoDetail data={detail} reload={fetchInventario} />
              </Row>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InventarioAgrupado;
