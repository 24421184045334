import React, { useEffect } from "react";
import { api, diveAPI } from "../config/api";
import { Container, Col, Row, Card } from "reactstrap";
import { Routes, Route } from "react-router-dom";
import { SideNavigation } from "./SideNavigation";
import { Inicio } from "./pages/Inicio";
import { AdminUsuarios } from "./pages/AdminUsuarios";
import { AdminModulos } from "./pages/AdminModulos";
import { AdminPermisos } from "./pages/AdminPermisos";
import { AdminCosechas } from "./pages/AdminCosechas";
import { InventarioLista } from "./pages/InventarioLista";
import { InventarioCarga } from "./pages/InventarioCarga";
import { PreenfrioCamaras } from "./pages/PreenfrioCamaras";
import { PreenfrioBitacora } from "./pages/preenfrioBitacora";
import { ReportesCargas } from "./pages/ReportesCargas";
// import { ReportesProduccion } from "./pages/ReportesProduccion";
import InventarioAgrupado from "./pages/Reportes nuez/Inventario.Agrupado";
import HojaDeCarga from "./pages/Reportes nuez/HojaDeCarga";
import Etiquetado from "./pages/Etiquetado";
import AltaEmpacador from "./pages/Empaque/AltaEmpacador";
import EscaneoEmpaque from "./pages/Empaque/EscaneoEmapaque";
import Estadisticas from "./pages/Empaque/Estadisticas";
import Reporte from "./pages/Empaque/Reporte";
import Swal from "sweetalert2";

export const Principal = () => {
  const getUnsincronizedData = () => {
    Swal.showLoading();
    api.get("api/etiquetado/unsincronize").then((response) => {
      response.data.length > 0 ? sincronizar(response.data) : Swal.close();
    });
  };

  const sincronizar = async (data) => {
    let id_list = [];
    await data.reduce(async (promise, current, index) => {
      const info = {
        productor: current.productor,
        campo: current.campo,
        producto: current.producto,
        variedad: current.variedad,
        empaque: current.empaque,
        fecha: current.fecha,
        folios: current.folios,
      };
      await promise;
      try {
        const response = await diveAPI.post("api/etiquetas/", info, {
          headers: { ID: current.id_sincronizado },
        });
        if (response.status === 201) {
          id_list.push(current.id_sincronizado);
        }
      } catch (e) {
        console.error(e);
      }
    }, Promise.resolve());
    id_list.length > 0 ? updateStatus(id_list) : Swal.close();
  };

  const updateStatus = (data) => {
    api
      .put("api/etiquetado/unsincronize/", { id: data })
      .then((response) => {})
      .catch((error) => console.log(error))
      .finally(() => {
        Swal.close();
      });
  };
  useEffect(() => {
    getUnsincronizedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={styles.contentDiv}>
      <SideNavigation />
      <div style={styles.contentMargin}>
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card body>
                <Routes>
                  <Route exact path="/" element={<Inicio />}></Route>
                  <Route
                    exact
                    path="/admin/usuarios"
                    element={<AdminUsuarios />}
                  ></Route>
                  <Route
                    exact
                    path="/admin/modulos"
                    element={<AdminModulos />}
                  ></Route>
                  <Route
                    exact
                    path="/admin/permisos"
                    element={<AdminPermisos />}
                  ></Route>
                  <Route
                    exact
                    path="/admin/cosechas"
                    element={<AdminCosechas />}
                  ></Route>
                  <Route
                    exact
                    path="/inventario/lista"
                    element={<InventarioLista />}
                  ></Route>
                  <Route
                    exact
                    path="/inventario/carga"
                    element={<InventarioCarga />}
                  ></Route>
                  <Route
                    exact
                    path="/preenfrio/camaras"
                    element={<PreenfrioCamaras />}
                  ></Route>
                  <Route
                    exact
                    path="/preenfrio/bitacora"
                    element={<PreenfrioBitacora />}
                  ></Route>
                  <Route
                    exact
                    path="/reportes/cargas"
                    element={<ReportesCargas />}
                  ></Route>
                  {/* <Route
                    exact
                    path="/reportes/produccion"
                    element={<ReportesProduccion />}
                  /> */}
                  <Route
                    exact
                    path="/etiquetado"
                    element={<Etiquetado />}
                  ></Route>
                  <Route
                    exact
                    path="/empaque/etiquetas"
                    element={<AltaEmpacador />}
                  />
                  <Route
                    exact
                    path="/empaque/escaneo"
                    element={<EscaneoEmpaque />}
                  />
                  <Route exact path="/empaque/reporte" element={<Reporte />} />
                  <Route
                    exact
                    path="/reportes/hojaCarga"
                    element={<HojaDeCarga />}
                  />
                  <Route
                    exact
                    path="/reportes/inventario/agrupado"
                    element={<InventarioAgrupado />}
                  />
                  <Route
                    exact
                    path="/empaque/estadisticas"
                    element={<Estadisticas />}
                  />
                </Routes>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const styles = {
  contentDiv: {
    display: "Flex",
    backgroundColor: "#f9f9f9",
  },
  contentMargin: {
    marginLeft: "10px",
    marginRight: "20px",
    marginTop: "10px",
    width: "100%",
  },
};
