import { useEffect, useState } from "react";
import { api } from "../../config/api";

export const useCultivo = () => {
  const [cultivos, setCultivos] = useState([]);

  useEffect(() => {
    api
      .get("api/cosecha/cosechasList", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCultivos(response.data);
      });
  }, []);

  return { cultivos };
};
