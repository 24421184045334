import React from "react";
import { useCountdown } from "../hooks/useCountDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Card } from "reactstrap";

export const PreenfrioCard = (props) => {
  const countDown = useCountdown(props.fecha);

  return (
    <Col md={3} sm={12}>
      <Card body className="my-2">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              backgroundColor: props.backgroundColor,
              width: "30px",
              height: "30px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 100,
            }}
          >
            <FontAwesomeIcon icon="fas fa-snowflake" color={props.iconColor} />
          </div>
          <label style={{ fontWeight: "bold" }}>{props.camara}</label>
        </div>
        <br />
        {props.estatus > 1 && <label>Ciclo #{props.ciclo}</label>}
        <div>
          {props.estatus === 1 && <label>Disponible</label>}

          {props.showTimer && (
            <div>
              {countDown[1] >= 0
                ? `${countDown[1]}:${countDown[2]}:${countDown[3]}`
                : "00:00:00"}
            </div>
          )}
        </div>
        <br />
        <button
          className="btn btn-info"
          onClick={props.onClick}
          disabled={props.disabled}
        >
          Detalle
        </button>
      </Card>
    </Col>
  );
};
