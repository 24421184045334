export const GenericList = ({
  data,
  renderItem,
  children,
  emptyListComponent,
}) => {
  return (
    <div>
      {children}
      <br />
      <div>
        {data.map((item, index) => (
          <div key={index}>{renderItem(item, index)}</div>
        ))}
        {data.length === 0 && emptyListComponent}
      </div>
    </div>
  );
};
