import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "reactstrap";
import Swal from "sweetalert2";
import { number } from "yup";
import { api } from "../../config/api";
import axios from "axios";
import { useEffect, useState } from "react";
const pesoSchema = number().moreThan(0).required();
export const PaletAgrupadoDetail = ({ data, reload }) => {
  const [info, setInfo] = useState([]);
  const handleClick = (palet) => {
    Swal.fire({
      title: `PESO PALET: ${palet}`,
      input: "number",
      inputAttributes: {
        min: "0",
      },
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#198754",
      cancelButtonColor: "#dc3545",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading,
      preConfirm: async (peso) => {
        try {
          const isValid = await pesoSchema.isValid(peso.trim());
          if (!isValid) {
            throw new Error("Peso ingresado no válido");
          }
          const data = { db: "NUEZ", palet: palet.trim(), peso: peso };
          const response = await api.put(
            "api/inventario/insert/detalle-inventario",
            { data: data },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + sessionStorage.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          Swal.showValidationMessage(`${error}`);
        }
      },
    }).then((response) => {
      if (response.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Peso actualizado",
          timer: 1500,
        });
        setTimeout(() => {
          reload();
        }, 1500);
      }
    });
  };
  const handleDelete = (palet) => {
    Swal.fire({
      icon: "warning",
      title: `¿Está seguro que desea eliminar el palet ${palet}?`,
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
      cancelButtonColor: "#198754",
      confirmButtonColor: "#dc3545",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await api.delete(
            `api/inventario/inventario-agrupado/?codigo_palet=${palet}&db=NUEZ`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + sessionStorage.token,
              },
            }
          );
        } catch (error) {
          let message = "Algo salió mal!";
          if (axios.isAxiosError(error)) {
            if (error.status === 400) {
              message = error.response.data.msg;
            }
          }
          Swal.showValidationMessage(`${message}`);
        }
      },
    }).then((res) => {
      if (res.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Palet eliminado",
          timer: 1500,
        });
        setTimeout(() => {
          reload();
        }, 1500);
      }
    });
  };
  useEffect(() => {
    setInfo(data);
  }, [data]);
  return (
    <Col className="table-responsive" style={{ maxHeight: "500px" }}>
      <table className="table">
        <thead>
          <tr>
            <th>Código palet</th>
            <th>Peso</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {info?.map((item, index) => (
            <tr key={index}>
              <td>{item.codigo_palet}</td>
              <td>{item.peso_pal} Kg</td>
              <td>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => handleClick(item.codigo_palet)}
                >
                  <FontAwesomeIcon icon="fa-solid fa-pencil" />
                </button>{" "}
                <button
                  onClick={() => handleDelete(item.codigo_palet)}
                  className="btn btn-outline-danger"
                >
                  <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Col>
  );
};
