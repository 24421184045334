import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row, Col, Table } from "reactstrap";
import { api } from "../../config/api";
import Swal from "sweetalert2";

export const AdminModulos = () => {
    const [arrModulos, SetArrModulos] = useState([]);

    const cargarListaModulos = async () => {
        await api.get('/api/administracion/get_modulos', { headers : { 'Content-Type': "application/json", 'Authorization': 'Token '+sessionStorage.token } }).then(res => {
            if(res.data !== false){
                SetArrModulos(res.data);
            }else{
                Swal.fire("No se encontraron datos!","","error");
            }
        });
    }
    useEffect(() => {
        cargarListaModulos();
    }, []);
    return (
        <>
            <Row>
                <Col xs="12">
                    <Row>
                        <Col xs="12">
                            <h2 className="text-center">MÓDULOS</h2>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs='12'>
                            <Table responsive size="sm" className="table-bordered table-condensed table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Módulo</th>
                                        <th>Padre</th>
                                        <th>Tipo</th>
                                        <th>Icono</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        arrModulos.map(row => {
                                            let moduloPadre = arrModulos.find(module => module.id_modulo === row.id_padre);
                                            moduloPadre = moduloPadre === undefined ? '' : `${row.id_padre} - ${moduloPadre['modulo']}`;
                                            return (
                                                <tr key={row.id_modulo}>
                                                    <td>{row.id_modulo}</td>
                                                    <td>{row.modulo}</td>
                                                    <td>{moduloPadre}</td>
                                                    <td>{row.tipo_modulo}</td>
                                                    <td>
                                                        <FontAwesomeIcon icon={row.icono}></FontAwesomeIcon> {row.icono}
                                                    </td>
                                                </tr>);
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}