import axios from "axios";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_AXIOS_BASE_URL}`,
});

export const altateca = axios.create({
  baseURL: `${process.env.REACT_APP_API_ALTATECA}`,
});

export const diveAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_DIVINE}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_DIVINE_TOKEN}`,
  },
});
