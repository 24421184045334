import React, { useState, useContext, createContext, useEffect } from "react";

const AppContext = createContext({
  isLoggedin: [],
  handlereLogIn: () => {},
});

export const UserProvider = ({ children }) => {
  const [isLoggedin, setIsLoggedin] = useState(false);

  const setLoginState = () => {
    try {
      sessionStorage.setItem("@login", 'true');
    } catch {}
  };

  const getLoginState = () => {
    try {
      const result = sessionStorage.getItem("@login");
      if (result) {
        return true;
      }
    } catch {}
    return false;
  };

  const handleLogIn = () => {
    setIsLoggedin(true);
  };

  const handleLogout = () => {
    setIsLoggedin(false);
    sessionStorage.clear()
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getLoginState();
      if (data) {
        setIsLoggedin(true);
      }
    };

    fetchData();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isLoggedin,
        handleLogIn,
        handleLogout,
        setLoginState,
        setIsLoggedin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
