import React from "react";
import { Row, Col } from "reactstrap";
import Logo from "../../images/ship-in_icon.png";

export const Inicio = () => {
  return (
    <Row>
      <Col xs="12">
        <div style={styles.container}>
          <img src={Logo} alt="ship-in" style={styles.logo} />
          <h1>Ship-In</h1>
        </div>
      </Col>
    </Row>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "50px",
  },
  logo: {
    height: "100px",
  },
};
