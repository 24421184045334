/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Input } from "reactstrap";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { PRESENTACION, PALET } from "../utils/constants";
import { InventarioFilterForm } from "../components/Inventario.Filter.Form";
import { InventarioTable } from "../components/Inventario.Table";
import { useInventario } from "../hooks/useInventario";

const initialValues = {
  id_cosecha: 0,
  tipo: PRESENTACION,
};

export const InventarioLista = () => {
  const hookInventario = useInventario();
  const [inventario, setInventario] = useState([]);
  const [inventarioTemp, setInventarioTemp] = useState([]);
  const [buscador, setBuscador] = useState("");
  const [total, setTotal] = useState("0");

  const onSubmit = async (values) => {
    try {
      const response = await hookInventario.fetchData(
        values.id_cosecha,
        values.tipo
      );
      setInventario(response);
      setInventarioTemp(response);
    } catch (error) {
      console.log(error);
    }
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
  });

  const descargarExcel = async () => {
    Swal.showLoading();
    await hookInventario.downloadExcel(values.id_cosecha, values.tipo);
    Swal.close();
  };

  useEffect(() => {
    if (buscador.length > 0) {
      let inventory = inventarioTemp;
      let temp = [];
      if (values.tipo === PRESENTACION) {
        temp = inventory.filter(
          (row) =>
            row.id_cosecha__cosecha.toLowerCase().indexOf(buscador) >= 0 ||
            row.cultivo.toLowerCase().indexOf(buscador) >= 0 ||
            (row.cultivo + " " + row.atributo + " " + row.size)
              .toLowerCase()
              .indexOf(buscador) >= 0 ||
            row.presentacion.toLowerCase().indexOf(buscador) >= 0 ||
            (row.envase + " " + row.bultos + " BULTOS")
              .toLowerCase()
              .indexOf(buscador) >= 0 ||
            row.tipo_siembra.toLowerCase().indexOf(buscador) >= 0
        );
      }
      if (values.tipo === PALET) {
        temp = inventory.filter(
          (row) =>
            row.codigo_palet.toLowerCase().indexOf(buscador) >= 0 ||
            row.id_cosecha__cosecha.toLowerCase().indexOf(buscador) >= 0 ||
            row.cultivo.toLowerCase().indexOf(buscador) >= 0 ||
            (row.cultivo + " " + row.atributo + " " + row.size)
              .toLowerCase()
              .indexOf(buscador) >= 0 ||
            row.presentacion.toLowerCase().indexOf(buscador) >= 0 ||
            (row.envase + " " + row.bultos + " BULTOS")
              .toLowerCase()
              .indexOf(buscador) >= 0 ||
            row.tipo_siembra.toLowerCase().indexOf(buscador) >= 0
        );
      }
      setInventario(temp);
    } else {
      setInventario(inventarioTemp);
    }
  }, [buscador]);

  useEffect(() => {
    if (values.tipo === PALET) {
      setTotal(inventario?.length);
      return;
    }

    let xtotal = 0;
    inventario?.forEach((item) => {
      xtotal += item.cantidad;
    });
    setTotal(xtotal);
  }, [inventario]);

  useEffect(() => {
    setInventario([]);
  }, [values.tipo]);

  return (
    <Row>
      <Col xs="12">
        <Row>
          <Col xs="12">
            <h2 className="text-center">INVENTARIO VIGENTE</h2>
          </Col>
        </Row>
        <InventarioFilterForm
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          downloadExcel={descargarExcel}
        />
        <br />
        <Row style={{ marginBottom: "5px" }}>
          <Col xs="12" sm="12" md="4" lg="3">
            <Input
              placeholder="Buscar"
              value={buscador}
              onChange={(e) => setBuscador(e.target.value)}
            ></Input>
          </Col>
          <Col xs="12" sm="12" md="4" lg="3">
            <label
              style={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Total: {total} Palets
            </label>
          </Col>
        </Row>
        <InventarioTable
          tipo={values.tipo}
          inventario={inventario}
          reload={handleSubmit}
        />
      </Col>
    </Row>
  );
};
