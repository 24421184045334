import { useEffect, useState } from "react";
import { api } from "../../config/api";
import { displayRequestError } from "../utils/request.error";

export const useInventarioAgrupado = (id_grupo) => {
  const [inventario, setInventario] = useState([]);
  const [detail, setDetail] = useState([]);

  const getInventario = async () => {
    try {
      const response = await api.get(`api/inventario/inventario-agrupado/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      });
      setInventario(response.data);
    } catch (error) {
      displayRequestError(error);
    }
  };

  useEffect(() => {
    const getInventarioDetail = async () => {
      const filter = id_grupo !== 0 ? `?id_grupo=${id_grupo}` : "";
      try {
        const response = await api.get(
          `api/inventario/inventario-agrupado/${filter}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + sessionStorage.token,
            },
          }
        );
        setDetail(response.data);
      } catch (error) {
        displayRequestError(error);
      }
    };
    if (id_grupo !== 0) {
      getInventarioDetail();
    }
  }, [id_grupo]);

  return { inventario, detail, getInventario };
};
