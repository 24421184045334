import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  CardBody,
  Card,
  CardHeader,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import { api } from "../../config/api";

const renderModuleName = (list, id_modulo_padre) => {
  const module = list.find((item) => item.id_modulo === id_modulo_padre);
  if (module) {
    return ".- " + module.modulo;
  }
  return "";
};

export const AdminPermisos = () => {
  const [users, setUsers] = useState([]);
  const [selectedOption, setSelectedOption] = useState("0");
  const [modulosWeb, setModulosWeb] = useState([]);
  const [modulosMovil, setModulosMovil] = useState([]);

  const savePermisos = () => {
    if (selectedOption === 0) {
      Swal.fire("Faltan datos", "Debe seleccionar un empleado", "error");
    } else {
      api
        .post(
          "/api/administracion/permisos/asignar",
          {
            id_usuario: parseInt(selectedOption),
            modulos: [...modulosWeb, ...modulosMovil],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + sessionStorage.token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            Swal.fire(
              "Se guardó con éxito",
              "Se han actualizado los accesos de usuario",
              "success"
            );
          } else {
            Swal.fire(
              "Algo ocurrió",
              "Comuniquese con el administrador del sistema",
              "error"
            );
          }
        });
    }
  };
  const selectedOptionChangeHandler = (value) => {
    setSelectedOption(value);
    if (value !== "0") {
      api
        .post(
          "/api/administracion/obtener/permisos/",
          { data: { idusuario: value, tipo_modulo: "web" } },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + sessionStorage.token,
            },
          }
        )
        .then((res) => {
          const menuUsuario = res.data;
          let menuTemp = [...modulosWeb];
          for (const row of menuTemp) {
            row["acceso"] = false;
          }
          for (const row of menuUsuario) {
            let index = menuTemp.findIndex(
              (modulo) => modulo.id_modulo === row.id_modulo
            );
            if (index !== undefined) {
              menuTemp[index]["acceso"] = true;
            }
          }
          setModulosWeb(menuTemp);
        });
      api
        .post(
          "/api/administracion/obtener/permisos/",
          { data: { idusuario: value, tipo_modulo: "movil" } },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + sessionStorage.token,
            },
          }
        )
        .then((res) => {
          const menuUsuario = res.data;
          let menuTemp = [...modulosMovil];
          for (const row of menuTemp) {
            row["acceso"] = false;
          }
          for (const row of menuUsuario) {
            let index = menuTemp.findIndex(
              (modulo) => modulo.id_modulo === row.id_modulo
            );
            if (index !== undefined) {
              menuTemp[index]["acceso"] = true;
            }
          }
          setModulosMovil(menuTemp);
        });
    } else {
      let menuTemp = [...modulosWeb];
      for (const row of menuTemp) {
        row["acceso"] = false;
      }
      setModulosWeb(menuTemp);
      menuTemp = [...modulosMovil];
      for (const row of menuTemp) {
        row["acceso"] = false;
      }
      setModulosMovil(menuTemp);
    }
  };
  function ordenarArreglo(arr) {
    let new_arr = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]["creado"] === false) {
        new_arr.push(arr[i]);
        arr[i]["creado"] = true;
        for (let y = 0; y < arr.length; y++) {
          if (arr[i].id_modulo === arr[y].id_padre) {
            new_arr.push(arr[y]);
            arr[y]["creado"] = true;
          }
        }
      } else {
        let suma = 1;
        let index = new_arr.findIndex(
          (row) => arr[i]["id_modulo"] === row.id_modulo
        );
        for (let y = 0; y < arr.length; y++) {
          if (arr[i]["id_modulo"] === arr[y]["id_padre"]) {
            new_arr.splice(index + suma, 0, arr[y]);
            arr[y]["creado"] = true;
            suma++;
          }
        }
      }
    }
    return new_arr;
  }
  const InputCheckboxChangeHandler = (event) => {
    let menuTemp =
      event.target.getAttribute("tipo") === "web"
        ? [...modulosWeb]
        : [...modulosMovil];

    const index = menuTemp.findIndex(
      (row) => row.id_modulo === parseInt(event.target.id)
    );
    let activateIndex = index;
    menuTemp[index]["acceso"] = !menuTemp[index]["acceso"];
    let padre = menuTemp[index]["id_padre"];

    if (padre !== null && menuTemp[index]["acceso"]) {
      do {
        activateIndex = menuTemp.findIndex(
          (row) => row.id_modulo === parseInt(padre)
        );
        menuTemp[activateIndex]["acceso"] = true;
        padre = menuTemp[activateIndex]["id_padre"];
      } while (padre !== null);
    }

    if (menuTemp[index]["acceso"] === false) {
      menuTemp = desactivarCasillas(menuTemp[index]["id_modulo"], menuTemp);
    }
    event.target.getAttribute("tipo") === "web"
      ? setModulosWeb(menuTemp)
      : setModulosMovil(menuTemp);
  };
  const desactivarCasillas = (id_padre, menuTemp) => {
    for (let i = 0; i < menuTemp.length; i++) {
      if (id_padre === menuTemp[i]["id_padre"]) {
        menuTemp[i]["acceso"] = false;
        menuTemp = desactivarCasillas(menuTemp[i]["id_modulo"], menuTemp);
      }
    }
    return menuTemp;
  };

  useEffect(() => {
    const getUserList = () => {
      api
        .get("/api/administracion/get_users", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        })
        .then((res) => {
          setUsers(res.data);
        });
    };
    const getModulosList = async () => {
      await api
        .get("/api/administracion/get_modulos", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        })
        .then((res) => {
          const modulos = res.data;
          let arrWeb = [];
          let arrMovil = [];
          for (const row of modulos) {
            if (row.tipo_modulo === "web") {
              arrWeb.push({ ...row, creado: false, acceso: false });
            } else {
              arrMovil.push({ ...row, creado: false, acceso: false });
            }
          }
          arrWeb = ordenarArreglo(arrWeb);
          arrMovil = ordenarArreglo(arrMovil);

          setModulosWeb(arrWeb);
          setModulosMovil(arrMovil);
        });
    };
    getUserList();
    getModulosList();
  }, []);
  return (
    <>
      <Row>
        <Col xs="12">
          <Row>
            <Col xs="12">
              <h2 className="text-center">PERMISOS</h2>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="6" lg="6">
              <label style={{ fontWeight: "bold" }}>Usuario</label>
              <Input
                className="form-control"
                type="select"
                value={selectedOption}
                onChange={(e) => {
                  selectedOptionChangeHandler(e.target.value);
                }}
              >
                <option value="0">Seleccionar usuario...</option>
                {users.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.first_name} {row.last_name} ({row.username})
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs="6" sm="6" md="3" lg="2">
              <Button
                className="btn-block"
                color="primary"
                onClick={() => {
                  savePermisos();
                }}
                style={{ marginTop: "32px" }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <Card>
                <CardHeader style={{ fontWeight: "bold" }}>
                  Permisos página WEB
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <Table
                        responsive
                        size="sm"
                        className="table-bordered table-condensed table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Módulo</th>
                            <th>Padre</th>
                            <th>Acceso</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modulosWeb.map((row) => (
                            <tr key={"tr" + row.id_modulo}>
                              <td>
                                {row.id_modulo} - {row.modulo}
                              </td>
                              <td>
                                {row.id_padre !== null && row.id_padre}
                                {row.id_padre !== null &&
                                  renderModuleName(modulosWeb, row.id_padre)}
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  id={row.id_modulo}
                                  tipo="web"
                                  onChange={InputCheckboxChangeHandler}
                                  className="form-control"
                                  checked={row.acceso}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <Card>
                <CardHeader style={{ fontWeight: "bold" }}>
                  Permisos App Móvil
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <Table
                        responsive
                        size="sm"
                        className="table-bordered table-condensed table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Módulo</th>
                            <th>Padre</th>
                            <th>Acceso</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modulosMovil.map((row) => (
                            <tr key={"tr" + row.id_modulo}>
                              <td>
                                {row.id_modulo} - {row.modulo}
                              </td>
                              <td>
                                {row.id_padre !== null && row.id_padre}
                                {row.id_padre !== null &&
                                  renderModuleName(modulosMovil, row.id_padre)}
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  id={row.id_modulo}
                                  tipo="movil"
                                  onChange={InputCheckboxChangeHandler}
                                  className="form-control"
                                  checked={row.acceso}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
