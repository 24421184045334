import Swal from "sweetalert2";
import { api } from "../../config/api";
import { displayRequestError, fireLoading } from "../utils/request.error";

export const useEmpleados = () => {
  const getEmpleado = async (nomina = "", empacador = "") => {
    fireLoading({ title: "", body: "" });
    let params = `?nomina=${nomina}`;
    if (nomina === "" && empacador !== "") {
      params = `?num_empacador=${empacador}`;
    }
    try {
      const response = await api.get(`api/empaque/empleados/${params}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      });
      Swal.close();
      return response.data;
    } catch (error) {
      displayRequestError(error);
      return [];
    }
  };

  return { getEmpleado };
};
