import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { api, diveAPI } from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/logo_divine_flavor.png";
import codigo from "../../images/google.png";
import Swal from "sweetalert2";

const Etiquetado = () => {
  const [cultivoList, setCultivoList] = useState([]);
  const [empaqueList, setEmpaqueList] = useState([]);
  const [empaqueOptions, setEmpaqueOptions] = useState([]);
  const [loteList, setLoteList] = useState([]);
  const [loteOptions, setLoteOptions] = useState([]);
  const [variedadList, setVariedadList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [campo, setCampo] = useState([]);
  const [form, setForm] = useState({
    id_cultivo: 0,
    cultivo: "",
    id_empaque: 0,
    empaque: "",
    cantidad: 0,
    id_lote: 0,
    lote: "",
    bd: "",
    id_variedad: 0,
    variedad: "",
    fecha: moment().format("YYYY-MM-DD"),
    id_configuracion: 0,
  });

  const handleCultivoChange = (event) => {
    setEmpaqueOptions([]);
    setLoteList([]);
    setLoteOptions([]);

    setVariedadList([]);
    const selected = cultivoList.find(
      (item) => item.id_cosecha === parseInt(event.target.value)
    );
    setForm({
      ...form,
      id_cultivo: event.target.value,
      cultivo: selected ? selected.cultivo : "",
      bd: selected ? selected.base_datos : "",
      id_configuracion: selected ? selected.id_configuracion : 0,
      id_lote: 0,
      lote: "",
      id_empaque: 0,
      empaque: "",
      id_variedad: 0,
      variedad: "",
    });

    if (parseInt(event.target.value) === 0) {
      return;
    }

    const empaque = empaqueList.find(
      (item) => item.id_cosecha === parseInt(event.target.value)
    );

    getCultivoInfo(selected.id_cultivo, selected.base_datos);

    setEmpaqueOptions(empaque.presentaciones);
  };

  const getCultivoInfo = (id_cultivo, base_datos) => {
    api
      .get(`api/etiquetado/etiqueta/?id_cultivo=${id_cultivo}&bd=${base_datos}`)
      .then((response) => {
        setLoteList(response.data[0]);
        setVariedadList(response.data[1]);
      });
  };

  const handleVariedadChange = (event) => {
    const selected = variedadList.find(
      (item) => item.id_variedad === event.target.value
    );
    setForm({
      ...form,
      id_variedad: event.target.value,
      variedad: selected ? selected.variedad : "",
    });
    if (parseInt(event.target.value) === 0) {
      setLoteOptions([]);
      return;
    }
    const lotes = loteList.filter(
      (item) => item.id_variedad === event.target.value
    );
    setLoteOptions(lotes);
  };

  const validateField = () => {
    if (
      form.id_cultivo === 0 ||
      form.id_empaque === 0 ||
      form.id_variedad === 0 ||
      form.id_lote === 0 ||
      form.cantidad === "" ||
      parseInt(form.cantidad) === 0
    ) {
      Swal.fire({
        icon: "info",
        title: "Campos vacios",
        text: "Favor de llenar todos los campos en el formularo",
      });
      return;
    }
    getFolioList();
  };

  const getFolioList = () => {
    setIsLoading(true);
    api
      .post("api/etiquetado/etiqueta/", { form })
      .then((response) => {
        sincronizar(response.data[1], response.data[0], response.data[2]);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          Swal.fire({
            icon: "error",
            title: "Favor de comunicarlo al equipo de desarrollo",
          });
        }

        if (error.response.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Impresora no encontrada",
            text: error.response.data.msg,
          });
        }
        if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Código zpl",
            text: error.response.data.msg,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sincronizar = (folios, fecha, id) => {
    const aux_campo = campo.find(
      (item) => item.id_configuracion === form.id_configuracion
    );
    let cultivo = form.cultivo;
    if (cultivo.includes(" - ")) {
      const cultivo_split = cultivo.split(" - ");
      cultivo = cultivo_split[0];
    }
    const info = {
      productor: aux_campo.empresa,
      campo: aux_campo.campo,
      producto: cultivo,
      variedad: form.variedad,
      empaque: form.empaque,
      fecha: moment(fecha).utc().startOf("day").format("YYYY-MM-DD"),
      folios: folios,
    };

    diveAPI
      .post("/api/etiquetas/", info, { headers: { ID: id } })
      .then((response) => {
        actualizar(folios);
      });
  };

  const actualizar = (folios) => {
    api.put("api/etiquetado/etiqueta/", { data: folios });
  };

  useEffect(() => {
    api
      .get("api/catalogos/getCultivos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      })
      .then((response) => {
        setCultivoList(response.data);
      });

    api
      .get("api/catalogos/obtenerCatalogos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      })
      .then((response) => {
        setEmpaqueList(response.data);
      });

    api.get("api/etiquetado/campo").then((response) => {
      setCampo(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (form.bd !== "") {
      api
        .get(`api/catalogos/fecha/?bd=${form.bd}`, {
          headers: {
            Authorization: "Token " + sessionStorage.token,
          },
        })
        .then((response) => {
          setForm({
            ...form,
            fecha: moment(response.data).utc().format("YYYY-MM-DD"),
          });
        });
    }
  }, [form.bd]);

  return (
    <div style={style.container}>
      <div>
        <h2 style={{ textAlign: "center" }}>Etiquetado</h2>
        <hr />
      </div>
      <div style={style.body}>
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={style.content}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={logo} alt="" style={{ height: 50, width: 50 }} />
                <h6 style={{ fontSize: 10 }}>Connect to the farm</h6>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h6>VAL0183758397759</h6>
                <img src={codigo} alt="" style={{ height: 50, width: 50 }} />
              </div>
            </div>
          </Col>
          <Col>
            <Row>
              <Col md={6} sm={12}>
                <label>Cultivo: </label>
                <select
                  className="form-control"
                  value={form.id_cultivo}
                  onChange={(value) => handleCultivoChange(value)}
                >
                  <option value={0}>Seleccionar...</option>
                  {cultivoList?.map((item) => (
                    <option key={item.id_cosecha} value={item.id_cosecha}>
                      {item.cultivo}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={6} sm={12}>
                <label>Variedad: </label>
                <select
                  className="form-control"
                  value={form.id_variedad}
                  onChange={(event) => handleVariedadChange(event)}
                >
                  <option>Seleccionar...</option>
                  {variedadList.map((item) => (
                    <option key={item.id_variedad} value={item.id_variedad}>
                      {item.variedad}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={6} sm={12}>
                <label>Lote: </label>
                <select
                  className="form-control"
                  value={form.id_lote}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      id_lote: value.target.value,
                      lote: loteList.find(
                        (item) => item.id_lote === value.target.value
                      ).lote,
                    });
                  }}
                >
                  <option value={0}>Seleccionar...</option>
                  {loteOptions?.map((item) => (
                    <option key={item.id_lote} value={item.id_lote}>
                      {item.lote}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={6} sm={12}>
                <label>Empaque: </label>
                <select
                  className="form-control"
                  value={form.id_empaque}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      id_empaque: value.target.value,
                      empaque: empaqueOptions.find(
                        (item) => item.codigo === value.target.value
                      ).producto,
                    });
                  }}
                >
                  <option value={0}>Seleccionar...</option>
                  {empaqueOptions?.map((item) => (
                    <option key={item.codigo} value={item.codigo}>
                      {item.producto}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={6} sm={12}>
                <label>Fecha:</label>
                <input
                  className="form-control"
                  type="date"
                  value={form.fecha}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      fecha: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col md={6} sm={12}>
                <label>Cantidad de etiquetas: </label>
                <input
                  min="0"
                  className="form-control"
                  type="number"
                  value={form.cantidad}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      cantidad: event.target.value,
                    });
                  }}
                />
              </Col>
              <Col>
                <button
                  disabled={isLoading}
                  className="btn btn-success btn-block"
                  style={{ marginTop: 30 }}
                  onClick={isLoading ? () => {} : () => validateField()}
                >
                  {isLoading ? (
                    <FontAwesomeIcon icon="fas fa-spinner" spin />
                  ) : (
                    <label>
                      <FontAwesomeIcon icon="fas fa-print" /> Imprimir{" "}
                    </label>
                  )}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
const style = {
  container: {
    margin: 10,
  },
  content: {
    borderStyle: "solid",
    borderColor: "black",
    borderRadius: 100,
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
};

export default Etiquetado;
