import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  InputGroup,
  InputGroupText,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from "../../images/divine.webp";
import { api } from "../../config/api";
import { useAppContext } from "../../config/UserProvider";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
library.add(fab, fas, far);

export const Login = () => {
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const useContext = useAppContext();
  const accesar = () => {
    setLoading(true);
    api
      .post("/api/administracion/get/user/", {
        data: { user: usuario, contra: password },
      })
      .then((res) => {
        const data = res.data;
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("user", JSON.stringify(data.user));
        useContext.setLoginState();
        useContext.handleLogIn();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 400) {
          Swal.fire(
            "No se puso iniciar sesión",
            error.response.data.msg,
            "error"
          );
        }
      });
  };
  return (
    <div style={styles.contentDiv}>
      <Container fluid>
        <Row>
          <Col
            sm={{ offset: 0, size: 12 }}
            md={{ offset: 1, size: 10 }}
            lg={{ offset: 1, size: 10 }}
          >
            <Row>
              <Col
                sm={{ offset: 0, size: 12 }}
                md={{ offset: 3, size: 6 }}
                lg={{ offset: 4, size: 4 }}
              >
                <Card body>
                  <Row>
                    <Col className="flex justify-center items-center" xs="12">
                      <img src={logo} alt="Logo" className="h-44 w-44" />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col className="text-center" style={{ color: "#2d3093" }}>
                      <h2>INICIO DE SESIÓN</h2>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <InputGroup>
                        <InputGroupText>
                          <FontAwesomeIcon icon="fas fa-user" />
                        </InputGroupText>
                        <Input
                          className="form-control"
                          value={usuario}
                          onKeyDown={(e) => {
                            e.key === "Enter" && accesar();
                          }}
                          onChange={(item) => {
                            setUsuario(item.target.value);
                          }}
                          placeholder="Usuario"
                          required
                        ></Input>
                      </InputGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <InputGroup>
                        <InputGroupText>
                          <FontAwesomeIcon icon="fas fa-lock" />
                        </InputGroupText>
                        <Input
                          className="form-control"
                          value={password}
                          onKeyDown={(e) => {
                            e.key === "Enter" && accesar();
                          }}
                          onChange={(item) => {
                            setPassword(item.target.value);
                          }}
                          placeholder="Contraseña"
                          type="password"
                          required
                        ></Input>
                      </InputGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Button
                        className="btn-block"
                        color="primary"
                        onClick={accesar}
                        disabled={loading}
                      >
                        {!loading ? (
                          "Iniciar sesión"
                        ) : (
                          <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const styles = {
  contentDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    backgroundColor: "#f1f1f1",
  },
};
