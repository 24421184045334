import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { fireSuccess } from "../utils/request.error";
import { useEffect, useState } from "react";
import { api } from "../../config/api";

export const EmpacadoresList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();

  const fetchEmpleados = async () => {
    setLoading(true);
    try {
      const response = await api.get(`api/empaque/empleados/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleDeleteEmpacador = (num_nomina) => {
    Swal.fire({
      icon: "warning",
      title: "¿Está seguro que desea eliminar el numero de empacador?",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
      cancelButtonColor: "#28a745",
      confirmButtonColor: "#dc3545",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await api.patch(
            `api/empaque/empleados/`,
            { num_nomina: num_nomina },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + sessionStorage.token,
              },
            }
          );
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
    }).then((res) => {
      if (res.isConfirmed) {
        fireSuccess({ title: "Número de empacador eliminado", body: "" });
        fetchEmpleados();
      }
    });
  };

  useEffect(() => {
    fetchEmpleados();
  }, []);

  if (loading) {
    return (
      <div style={style.container}>
        <FontAwesomeIcon icon="fas fa-spinner" size="lg" spin />
      </div>
    );
  }

  return (
    <Row>
      <Col className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Empacador</th>
              <th>Nómina</th>
              <th>Nombre</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((empacador) => (
              <tr key={empacador.num_nomina}>
                <td>{empacador.num_empacador}</td>
                <td>{empacador.num_nomina}</td>
                <td>{`${empacador.nombre} ${empacador.paterno} ${empacador.materno}`}</td>
                <td>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDeleteEmpacador(empacador.num_nomina)}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

const style = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "25px",
    margin: "20px",
    padding: "20px",
  },
};
