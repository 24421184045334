import { useFormik } from "formik";
import { userSchema } from "../utils/validations";

export function useUserForm({ initialValues, onSubmit }) {
  const {
    values,
    setValues,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    isValid,
    validateForm,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: userSchema,
    enableReinitialize: true,
  });

  const handleInputChange = (e) =>
    setFieldValue(
      e.currentTarget.getAttribute("name") ?? "",
      e.currentTarget.value
    );

  return {
    values,
    setValues,
    handleInputChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    isValid,
    validateForm,
    resetForm,
  };
}
