import { useState } from "react";
import { api } from "../../config/api";
import FileSaver from "file-saver";

export const useInventario = () => {
  const [loading, setLoading] = useState(false);

  const fetchData = async (id_cosecha, tipo) => {
    setLoading(true);
    try {
      const response = await api.get(
        `/api/inventario/listado?id_cosecha=${id_cosecha}&tipo=${tipo}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  const downloadExcel = async (id_cosecha, tipo) => {
    try {
      const response = await api.post(
        "/api/inventario/excelInventarioVigente",
        { id_cosecha: id_cosecha, tipo: tipo },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
          responseType: "blob",
        }
      );
      const effectiveFileName = "Inventario vigente.xls";
      if (response.data !== false) {
        FileSaver.saveAs(response.data, effectiveFileName);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { loading, fetchData, downloadExcel };
};
