import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { api } from "../../config/api";
import { CargaTarjeta } from "../generales/CargaTarjeta";
import Select from "react-select";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";

const meses = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const InventarioCarga = () => {
  const [fecha, setFecha] = useState("");
  const [fechaProg, setFechaProg] = useState("");
  const [cargasText, setCargasText] = useState("Cargas programadas");
  const [cargas, setCargas] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalInventario, setModalInventario] = useState(false);
  const [nuevaCarga, setNuevaCarga] = useState([]);
  const [cantidad, setCantidad] = useState("0");
  const [idCarga, setIdCarga] = useState(0);
  const [comparativo, setComparativo] = useState([]);
  const [existencia, setExistencia] = useState(0);
  const [propuesto, setPropuesto] = useState(0);

  const [optionsComparativo, setOptionsComparativo] = useState([]);
  const [compar, setCompar] = useState({ value: undefined, label: "" });

  const [cultivoArr, setCultivoArr] = useState([]);
  const [atributoArr, setAtributoArr] = useState({
    value: undefined,
    label: "",
  });
  const [sizeArr, setSizeArr] = useState([]);
  const [presentacionArr, setPresentacionArr] = useState({
    value: undefined,
    label: "",
  });
  const [etiquetaArr, setEtiquetaArr] = useState({
    value: undefined,
    label: "",
  });
  const [clientesArr, setClientesArr] = useState({
    value: undefined,
    label: "",
  });
  const [tipoSiembraArr, setTipoSiembraArr] = useState([]);
  window.clientesArr = clientesArr;
  const [optionsAtributo, setOptionsAtributo] = useState([]);
  const [optionsPresentacion, setOptionsPresentacion] = useState([]);
  const [optionsEtiqueta, setOptionsEtiqueta] = useState([]);
  const [optionsClientes, setOptionsClientes] = useState([]);

  const [viaje, setViaje] = useState("0");

  const [cultivo, setCultivo] = useState(undefined);
  const [atributo, setAtributo] = useState(undefined);
  const [size, setSize] = useState(undefined);
  const [presentacion, setPresentacion] = useState(undefined);
  const [etiqueta, setEtiqueta] = useState(undefined);
  const [cliente, setCliente] = useState(undefined);
  const [tipoSiembra, setTipoSiembra] = useState(undefined);

  const [producto, setProducto] = useState("");

  const [cargado, setCargado] = useState(false);

  const toggle = () => {
    setModal(!modal);
    if (modal === false) {
      setCantidad("0");
    }
  };
  const toggleInventario = () => {
    setModalInventario(!modalInventario);
  };
  const agregarProducto = () => {
    let _cultivo =
      cultivo != "0" && cultivo != undefined
        ? cultivoArr.find((row) => row.id_cosecha == cultivo)["cultivo"]
        : "0";
    let _atributo =
      atributo != undefined ? atributoArr[atributo]["v_nombre_col"] : "0";
    let _size = size;
    let _etiqueta =
      etiqueta != undefined ? etiquetaArr[etiqueta]["v_nombre_eti"] : "0";
    let _tipo_siembra = tipoSiembra;

    let _presentacion = "0";
    let _envase = "0";
    let _bultos = "0";

    if (presentacion != undefined) {
      let temp = presentacionArr[presentacion];
      _presentacion = temp["producto"];
      _envase = temp["envase"];
      _bultos = temp["bultos"];
    }

    if (
      _cultivo == "0" ||
      _atributo == "0" ||
      _size == "0" ||
      _presentacion == "0" ||
      _etiqueta == "0" ||
      _envase == "0" ||
      _bultos == "0" ||
      _tipo_siembra == "0"
    ) {
      Swal.fire("Faltan datos", "Debe seleccionar todas las opciones", "error");
    } else {
      if (cliente == undefined && viaje != "E") {
        Swal.fire("Faltan datos", "Debes seleccionar un cliente", "error");
      } else {
        if (cantidad > 0 && cantidad !== "") {
          let nuevoRow = {
            cultivo: _cultivo,
            atributo: _atributo,
            size: _size,
            presentacion: _presentacion + " " + _etiqueta,
            envase: _envase,
            bultos: _bultos,
            tipo_siembra: _tipo_siembra,
            id_cosecha: cultivo,
            existencia: existencia,
            cliente: cliente == undefined ? 1 : parseInt(cliente),
          };
          let arr = [...nuevaCarga];
          let existe = nuevaCarga.find((row) => {
            if (
              row.cultivo === nuevoRow.cultivo &&
              row.atributo === nuevoRow.atributo &&
              row.size === nuevoRow.size &&
              row.presentacion === nuevoRow.presentacion &&
              row.envase === nuevoRow.envase &&
              row.tipo_siembra === nuevoRow.tipo_siembra &&
              row.bultos === nuevoRow.bultos &&
              row.id_cosecha == nuevoRow.id_cosecha &&
              row.cliente == nuevoRow.cliente
            ) {
              return true;
            } else {
              return false;
            }
          });
          if (existe) {
            Swal.fire({
              title: "Producto repetido",
              text: "¿Deseas actualizar la cantidad?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si, actualizar!",
            }).then((result) => {
              if (result.isConfirmed) {
                let indexCarga = arr.findIndex(
                  (row) =>
                    row.cultivo === nuevoRow.cultivo &&
                    row.atributo === nuevoRow.atributo &&
                    row.size === nuevoRow.size &&
                    row.presentacion === nuevoRow.presentacion &&
                    row.envase === nuevoRow.envase &&
                    row.tipo_siembra === nuevoRow.tipo_siembra &&
                    row.bultos === nuevoRow.bultos &&
                    row.id_cosecha == nuevoRow.id_cosecha &&
                    row.cliente == nuevoRow.cliente
                );
                arr[indexCarga]["cantidad"] = cantidad;
                setNuevaCarga(arr);
                limpiarCamposCarga();
              }
            });
          } else {
            nuevoRow["cantidad"] = cantidad;
            arr.push(nuevoRow);
            setNuevaCarga(arr);
            limpiarCamposCarga();
          }
        } else {
          Swal.fire(
            "Faltan datos",
            "La cantidad debe ser un número entero positivo",
            "error"
          );
        }
      }
    }
  };
  const limpiarCamposCarga = () => {
    setCantidad("0");
    setExistencia("0");
    setPropuesto("0");

    setAtributo(undefined);
    setSize(0);
    setPresentacion(undefined);
    setEtiqueta(undefined);
    setTipoSiembra(0);
    setCliente(undefined);
    setCompar(undefined);
  };
  const quitarProductoCarga = (index) => {
    let arr = [...nuevaCarga];
    arr.splice(index, 1);
    setNuevaCarga(arr);
  };
  const abrirModalNuevaCarga = () => {
    if (idCarga != 0) {
      setNuevaCarga([]);
    }
    actualizarComparativa(cultivo);
    setIdCarga(0);
    toggle();
  };
  const abrirComparativa = () => {
    actualizarComparativa().then(() => {
      toggleInventario();
    });
  };
  const actualizarComparativa = async (id_cultivo = 0) => {
    api
      .get("/api/carga/comparativoPropuesta", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      })
      .then((res) => {
        let inventario = res.data.inventario;
        let propuesta = res.data.propuesta;
        for (let i = 0; i < inventario.length; i++) {
          inventario[i]["propuesta"] = 0;
        }
        for (const row of propuesta) {
          let ban = false;
          for (let i = 0; i < inventario.length; i++) {
            if (
              row.cultivo === inventario[i].cultivo &&
              row.atributo === inventario[i].atributo &&
              row.size === inventario[i].size &&
              row.presentacion === inventario[i].presentacion &&
              row.envase === inventario[i].envase &&
              row.tipo_siembra === inventario[i].tipo_siembra &&
              row.bultos === inventario[i].bultos
            ) {
              inventario[i]["propuesta"] = row.cantidad;
              ban = true;
            }
          }
          if (!ban) {
            inventario.push({
              cultivo: row.cultivo,
              atributo: row.atributo,
              size: row.size,
              presentacion: row.presentacion,
              envase: row.envase,
              tipo_siembra: row.tipo_siembra,
              bultos: row.bultos,
              cantidad: 0,
              propuesta: row.cantidad,
            });
          }
        }
        let arrTemp = [];
        if (id_cultivo != 0) {
          for (const [i, row] of inventario.entries()) {
            if (
              row.cultivo ==
              cultivoArr.find((row) => row.id_cosecha == id_cultivo)["cultivo"]
            ) {
              arrTemp.push({
                value: i,
                label:
                  row.cultivo +
                  " " +
                  row.atributo +
                  " " +
                  row.size +
                  " " +
                  row.presentacion +
                  " " +
                  row.envase +
                  " " +
                  row.bultos +
                  " BULTOS " +
                  row.tipo_siembra,
              });
            }
          }
        }
        setOptionsComparativo(arrTemp);
        setComparativo(inventario);
      });
  };
  const editarCargabutton = (id) => {
    setIdCarga(id);
    const registro = cargas.find((row) => row.id_carga == id);
    setCompar(undefined);
    for (const [i, row] of registro.propuesta.entries()) {
      let producto = `${row.cultivo} ${row.atributo} ${row.size} ${row.presentacion} ${row.envase} ${row.bultos} BULTOS ${row.tipo_siembra}`;
      let existente = comparativo.find(
        (item) =>
          `${item.cultivo} ${item.atributo} ${item.size} ${item.presentacion} ${item.envase} ${item.bultos} BULTOS ${item.tipo_siembra}` ==
          producto
      );
      if (existente != undefined) {
        registro.propuesta[i]["existencia"] = existente["cantidad"];
      } else {
        registro.propuesta[i]["existencia"] = 0;
      }
    }
    setNuevaCarga(registro.propuesta);
    toggle();
  };
  const eliminarCarga = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La carga #" + id + " sera eliminada.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post(
            "/api/carga/eliminarCarga",
            { id_carga: id, id_usuario: JSON.parse(sessionStorage.user)["id"] },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + sessionStorage.token,
              },
            }
          )
          .then((res) => {
            if (res.data) {
              Swal.fire("Se eliminó con éxito", "", "success");
              cargasPorFecha();
            } else {
              Swal.fire(
                "Algo ocurrió",
                "Comuniquese con el administrador del sistema",
                "success"
              );
            }
          });
      }
    });
  };
  const cargasPorFecha = () => {
    const fecha_reverse =
      fecha.substring(8, 10) +
      "-" +
      fecha.substring(5, 7) +
      "-" +
      fecha.substring(0, 4);
    setCargasText("Cargas programadas (" + fecha_reverse + ")");
    Swal.close();
    Swal.showLoading();
    api
      .post(
        "/api/carga/cargasList",
        { fecha },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.token,
          },
        }
      )
      .then((res) => {
        Swal.close();
        setCargas(res.data);
      });
  };
  const guardarCarga = () => {
    if (nuevaCarga.length == 0) {
      Swal.fire("Faltan datos", "Debes añadir productos a la carga", "error");
    } else {
      if (viaje == "E") {
        for (let i = 0; i < nuevaCarga.length; i++) {
          nuevaCarga[i]["cliente"] = 1;
        }
      }
      if (viaje == "0") {
        Swal.fire("Faltan datos", "Debes indicar el tipo de viaje", "error");
      } else {
        Swal.fire({
          title:
            idCarga == 0 ? "Terminar carga" : "Modificando carga #" + idCarga,
          text:
            idCarga == 0
              ? "Se creará una nueva carga"
              : "Se guardaran los cambios realizados en esta carga",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, guardar!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.showLoading();
            api
              .post(
                "/api/carga/guardarCarga",
                {
                  fecha: fechaProg,
                  detalle: nuevaCarga,
                  tipo_viaje: viaje,
                  id_usuario: JSON.parse(sessionStorage.user)["id"],
                  id_carga: idCarga,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + sessionStorage.token,
                  },
                }
              )
              .then((res) => {
                Swal.close();
                if (res.data) {
                  toggle();
                  Swal.fire(
                    "Se guardó con éxito",
                    "La carga se creo correctamente",
                    "success"
                  );
                  cargasPorFecha();
                  setNuevaCarga([]);
                } else {
                  Swal.fire(
                    "Algo ocurrió",
                    "Comunicate con el administrador del sistema.",
                    "error"
                  );
                }
              });
          }
        });
      }
    }
  };
  const seleccionExistentes = (option) => {
    setCompar(option);
    let item = comparativo[option];
    let seleccion =
      item["presentacion"] + " " + item["envase"] + " " + item["bultos"];
    setAtributo(
      atributoArr.findIndex((row) => row.v_nombre_col == item.atributo)
    );
    setSize(item.size);
    setTipoSiembra(item.tipo_siembra);
    for (const [x, row] of presentacionArr.entries()) {
      for (const [i, elem] of etiquetaArr.entries()) {
        let text =
          row["producto"] +
          " " +
          elem["v_nombre_eti"] +
          " " +
          row["envase"] +
          " " +
          row["bultos"];
        if (seleccion == text) {
          setPresentacion(x);
          setEtiqueta(i);
          break;
        }
      }
    }
  };
  const mostrarDatosEditar = (pos) => {
    let item = nuevaCarga[pos];
    if (cultivo != item.id_cosecha) {
      cambioCultivo(item.id_cosecha);
      Swal.fire(
        "Los catálogos se cargaron con éxito",
        "Por favor vuelva a dar clic en editar",
        "success"
      );
    } else {
      let seleccion =
        item["presentacion"] + " " + item["envase"] + " " + item["bultos"];
      setAtributo(
        atributoArr.findIndex((row) => row.v_nombre_col == item.atributo)
      );
      setSize(item.size);
      setTipoSiembra(item.tipo_siembra);
      let client = clientesArr.find(
        (row) => parseInt(row.c_codigo_dis) == item.cliente
      );
      setCliente(client.c_codigo_dis);
      for (const [x, row] of presentacionArr.entries()) {
        for (const [i, elem] of etiquetaArr.entries()) {
          let text =
            row["producto"] +
            " " +
            elem["v_nombre_eti"] +
            " " +
            row["envase"] +
            " " +
            row["bultos"];
          if (seleccion == text) {
            setPresentacion(x);
            setEtiqueta(i);
            break;
          }
        }
      }
    }
  };
  const cambioCultivo = (id_cultivo) => {
    setCultivo(id_cultivo);
    actualizarComparativa(id_cultivo);
    setCompar({ value: undefined, label: "" });
    if (id_cultivo == 0) {
      setAtributoArr({ value: undefined, label: "" });
      setSizeArr([]);
      setPresentacionArr({ value: undefined, label: "" });
      setEtiquetaArr({ value: undefined, label: "" });
      setClientesArr({ value: undefined, label: "" });
      setTipoSiembraArr([]);

      setAtributo(undefined);
      setSize(0);
      setPresentacion(undefined);
      setEtiqueta(undefined);
      setCliente(undefined);
      setTipoSiembra(0);

      setOptionsAtributo([]);
      setOptionsPresentacion([]);
      setOptionsEtiqueta([]);
      setOptionsClientes([]);
    } else {
      const cosecha = cultivoArr.find((row) => row.id_cosecha == id_cultivo);
      setAtributo(undefined);
      setSize(0);
      setPresentacion(undefined);
      setEtiqueta(undefined);
      setCliente(undefined);
      setTipoSiembra(0);

      setAtributoArr(cosecha.atributos);
      setSizeArr(cosecha.sizes);
      setPresentacionArr(cosecha.presentaciones);
      setEtiquetaArr(cosecha.etiquetas);
      setClientesArr(cosecha.clientes);
      setTipoSiembraArr(cosecha.siembra);

      let arrTemp = cosecha.atributos.map((row, i) => {
        return { value: i, label: row.v_nombre_col };
      });
      setOptionsAtributo(arrTemp);
      arrTemp = cosecha.presentaciones.map((row, i) => {
        return {
          value: i,
          label: row.producto + " " + row.envase + " " + row.bultos + " BULTOS",
        };
      });
      setOptionsPresentacion(arrTemp);
      arrTemp = cosecha.etiquetas.map((row, i) => {
        return { value: i, label: row.v_nombre_eti };
      });
      setOptionsEtiqueta(arrTemp);
      arrTemp = cosecha.clientes.map((row, i) => {
        return { value: row.c_codigo_dis, label: row.v_nombre_dis };
      });
      setOptionsClientes(arrTemp);
    }
  };
  const excelComparativo = () => {
    api
      .get("/api/carga/descargarExcelComparativoPropuesta", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
        responseType: "blob",
      })
      .then((response) => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? "Ship-In - Comparativo Inventario y propuestas.xlsx"
            : suggestedFileName;
        FileSaver.saveAs(response.data, effectiveFileName);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    let fecha = new Date();
    const fecha_normal =
      fecha.getFullYear() +
      "-" +
      meses[fecha.getMonth()] +
      "-" +
      (fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate());
    setFecha(fecha_normal);
    setFechaProg(fecha_normal);
    actualizarComparativa();
  }, []);
  useEffect(() => {
    if (fecha !== "") {
      cargasPorFecha();
    }
  }, [fecha]);
  useEffect(() => {
    api
      .get("/api/catalogos/obtenerCatalogos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + sessionStorage.token,
        },
      })
      .then((res) => {
        setCultivoArr(res.data);
      });
  }, []);
  useEffect(() => {
    if (viaje == "E") {
      setCliente(1);
    } else {
      setCliente(undefined);
    }
  }, [viaje]);
  useEffect(() => {
    if (cargado) {
      let _cultivo =
        cultivo != undefined && cultivo != "0"
          ? cultivoArr.find((row) => row.id_cosecha == cultivo)["cultivo"]
          : "";
      let _atributo =
        atributo != undefined ? atributoArr[atributo]["v_nombre_col"] : "";
      let _size = size != "0" ? size : "";
      let _etiqueta =
        etiqueta != undefined ? etiquetaArr[etiqueta]["v_nombre_eti"] : "";
      let _tipo_siembra = tipoSiembra != "0" ? tipoSiembra : "";

      let _presentacion = "";
      let _envase = "";
      let _bultos = "";
      let _bultos_text = "";

      if (presentacion != undefined) {
        let temp = presentacionArr[presentacion];
        _presentacion = temp["producto"];
        _envase = temp["envase"];
        _bultos = temp["bultos"];
        _bultos_text = temp["bultos"] + " BULTOS";
      }

      setProducto(
        _cultivo +
          " " +
          _atributo +
          " " +
          _size +
          " " +
          _presentacion +
          " " +
          _etiqueta +
          " " +
          _envase +
          " " +
          _bultos_text +
          " " +
          _tipo_siembra
      );
      buscarProducto({
        cultivo: _cultivo,
        atributo: _atributo,
        size: _size,
        presentacion: _presentacion + " " + _etiqueta,
        envase: _envase,
        bultos: _bultos,
        tipo_siembra: _tipo_siembra,
      });
    } else {
      setCargado(true);
    }
  }, [cultivo, atributo, size, presentacion, etiqueta, tipoSiembra]);
  const buscarProducto = (producto) => {
    for (const row of comparativo) {
      if (
        row.cultivo === producto.cultivo &&
        row.atributo === producto.atributo &&
        row.size === producto.size &&
        row.presentacion === producto.presentacion &&
        row.envase === producto.envase &&
        row.tipo_siembra === producto.tipo_siembra &&
        row.bultos === producto.bultos
      ) {
        setExistencia(row.cantidad);
        setPropuesto(row.propuesta);
        break;
      } else {
        setExistencia(0);
        setPropuesto(0);
      }
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col
              xs="12"
              sm="12"
              md={{ offset: 3, size: 6 }}
              lg={{ offset: 3, size: 6 }}
            >
              <h2 className="text-center">CREAR CARGAS</h2>
            </Col>
            <Col xs="12" sm="12" md="3" lg="3">
              <Button
                className="btn-block"
                color="primary"
                onClick={() => abrirComparativa()}
              >
                Inventario
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="3" lg="3">
              <label style={{ fontWeight: "bold" }}>Consultar fecha:</label>
              <Input
                type="date"
                value={fecha}
                onChange={(e) => setFecha(e.target.value)}
              ></Input>
            </Col>
            <Col
              xs="12"
              sm="12"
              md={{ offset: 3, size: 6 }}
              lg={{ offset: 3, size: 6 }}
            >
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md={{ offset: 6, size: 6 }}
                  lg={{ offset: 6, size: 6 }}
                >
                  <Button
                    className="btn-block"
                    style={{ marginTop: "31px" }}
                    onClick={() => abrirModalNuevaCarga()}
                    color="info"
                  >
                    Crear carga
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <span style={{ fontWeight: "bold" }}>{cargasText}</span>
                </CardHeader>
                <CardBody style={{ height: "70vh", overflowY: "scroll" }}>
                  {cargas.length > 0 && cultivoArr.length != 0 ? (
                    cargas.map((row) => (
                      <CargaTarjeta
                        key={row.id_carga}
                        data={row}
                        cultivos={cultivoArr}
                        functionEdit={editarCargabutton}
                        functionDelete={eliminarCarga}
                      ></CargaTarjeta>
                    ))
                  ) : (
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <h3 className="text-center">
                              Sin cargas programadas
                            </h3>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader>Programar carga</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12" sm="12" md="4" lg="4">
              <label style={{ fontWeight: "bold" }}>Fecha de carga:</label>
              <Input
                type="date"
                value={fechaProg}
                onChange={(e) => setFechaProg(e.target.value)}
              ></Input>
            </Col>
            <Col xs="12" sm="12" md="3" lg="3">
              <label style={{ fontWeight: "bold" }}>Tipo de viaje</label>
              <Input
                className="form-control"
                type="select"
                value={viaje}
                onChange={(e) => setViaje(e.target.value)}
              >
                <option value={"0"}>Seleccionar...</option>
                <option value={"E"}>Exportación</option>
                <option value={"N"}>Nacional</option>
              </Input>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="3" lg="3">
              <label style={{ fontWeight: "bold" }}>Cultivo</label>
              <Input
                className="form-control"
                type="select"
                value={cultivo}
                onChange={(e) => cambioCultivo(e.target.value)}
              >
                <option value={0}>Seleccionar...</option>
                {cultivoArr.map((row) => (
                  <option key={row.id_cosecha} value={row.id_cosecha}>
                    {row.cultivo}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <label style={{ fontWeight: "bold" }}>Producto</label>
              <Select
                options={optionsAtributo}
                value={optionsAtributo.filter((row) => row.value == atributo)}
                onChange={(e) => setAtributo(e.value)}
                placeholder="Seleccionar un producto..."
              />
            </Col>
            <Col>
              <label style={{ fontWeight: "bold" }}>Tamaño</label>
              <Input
                className="form-control"
                type="select"
                value={size}
                onChange={(e) => setSize(e.target.value)}
              >
                <option value={0}>Seleccionar...</option>
                {sizeArr.map((row) => (
                  <option key={row.v_nombre_tam}>{row.v_nombre_tam}</option>
                ))}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="4" lg="4">
              <label style={{ fontWeight: "bold" }}>Presentación</label>
              <Select
                options={optionsPresentacion}
                value={optionsPresentacion.filter(
                  (row) => row.value == presentacion
                )}
                onChange={(e) => setPresentacion(e.value)}
                placeholder="Seleccionar un producto..."
              />
            </Col>
            <Col xs="12" sm="12" md="4" lg="4">
              <label style={{ fontWeight: "bold" }}>Etiqueta</label>
              <Select
                options={optionsEtiqueta}
                value={optionsEtiqueta.filter((row) => row.value == etiqueta)}
                onChange={(e) => setEtiqueta(e.value)}
                placeholder="Seleccionar un producto..."
              />
            </Col>
            <Col xs="12" sm="12" md="4" lg="4">
              <label style={{ fontWeight: "bold" }}>Tipo siembra</label>
              <Input
                className="form-control"
                type="select"
                value={tipoSiembra}
                onChange={(e) => setTipoSiembra(e.target.value)}
              >
                <option value={0}>Seleccionar...</option>
                {tipoSiembraArr.map((row) => (
                  <option key={row.v_descripcion_cts}>
                    {row.v_descripcion_cts}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
          {viaje != "E" && (
            <Row>
              <Col xs="12" sm="12" md="12" lg="12">
                <label style={{ fontWeight: "bold" }}>Cliente</label>
                <Select
                  options={optionsClientes}
                  value={optionsClientes.filter((row) => row.value == cliente)}
                  onChange={(e) => setCliente(e.value)}
                  placeholder="Seleccionar un producto..."
                />
              </Col>
            </Row>
          )}
          <hr />
          <Row>
            {cultivo != 0 && cultivo != undefined && (
              <Col xs="12">
                <label style={{ fontWeight: "bold" }}>
                  Cargar productos de inventario:
                </label>
                <Select
                  options={optionsComparativo}
                  value={optionsComparativo.filter(
                    (row) => row.value == compar
                  )}
                  onChange={(e) => seleccionExistentes(e.value)}
                  placeholder="Seleccionar un producto..."
                />
              </Col>
            )}
            <Col xs="12">
              <label style={{ fontWeight: "bold" }}>
                Producto seleccionado:
              </label>
              <Input
                value={producto}
                placeholder="Producto seleccionado"
                disabled
              ></Input>
            </Col>
            <Col xs="12" sm="12" md="3" lg="3">
              <label style={{ fontWeight: "bold" }}>Existencía</label>
              <Input
                type="number"
                min="0"
                value={existencia}
                placeholder="0"
                disabled
              ></Input>
            </Col>
            <Col xs="12" sm="12" md="3" lg="3">
              <label style={{ fontWeight: "bold" }}>Propuesto</label>
              <Input
                type="number"
                min="0"
                value={propuesto}
                placeholder="0"
                disabled
              ></Input>
            </Col>
            <Col xs="12" sm="12" md="3" lg="3">
              <label style={{ fontWeight: "bold" }}>Cantidad</label>
              <Input
                type="number"
                min="0"
                value={cantidad}
                onChange={(e) => setCantidad(e.target.value)}
                placeholder="0"
              ></Input>
            </Col>
            <Col xs="12" sm="12" md="3" lg="3">
              <Button
                className="btn-block"
                onClick={() => agregarProducto()}
                style={{ marginTop: "31px" }}
                color="primary"
              >
                Añadir
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs="12">
              <Table
                responsive
                size="sm"
                className="table-bordered table-condensed table-hover"
              >
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Existencia</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {nuevaCarga.map((row, index) => (
                    <tr key={Math.random()}>
                      <td>
                        {row.cultivo +
                          " " +
                          row.atributo +
                          " " +
                          row.size +
                          " " +
                          row.presentacion +
                          " " +
                          row.envase +
                          " " +
                          row.bultos +
                          " BULTOS " +
                          row.tipo_siembra}
                      </td>
                      <td>{row.cantidad}</td>
                      <td>{row.existencia}</td>
                      <td>
                        <Button
                          className="btn-sm"
                          onClick={() => mostrarDatosEditar(index)}
                          color="warning"
                        >
                          <FontAwesomeIcon icon="fas fa-pencil" />
                        </Button>{" "}
                        <Button
                          className="btn-sm"
                          onClick={() => quitarProductoCarga(index)}
                          color="danger"
                        >
                          <FontAwesomeIcon icon="fas fa-trash-alt" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => guardarCarga()}>
            Guardar
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalInventario} toggle={toggleInventario} size="xl">
        <ModalHeader>
          Comparativa de inventario contra propuestas de carga
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="3" style={{ marginBottom: "5px" }}>
              <Button color="success" onClick={excelComparativo}>
                Descargar excel
              </Button>
            </Col>
            <Col xs="12">
              <Table
                responsive
                size="sm"
                className="table-bordered table-condensed table-hover"
              >
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Existencia</th>
                    <th>Propuesto</th>
                    <th>Piso</th>
                  </tr>
                </thead>
                <tbody>
                  {comparativo.map((row) => (
                    <tr key={Math.random()}>
                      <td>
                        {row.cultivo +
                          " " +
                          row.atributo +
                          " " +
                          row.size +
                          " " +
                          row.presentacion +
                          " " +
                          row.envase +
                          " " +
                          row.bultos +
                          " BULTOS " +
                          row.tipo_siembra}
                      </td>
                      <td>{row.cantidad}</td>
                      <td>{row.propuesta}</td>
                      <td>{row.cantidad - row.propuesta}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleInventario}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
